import dayjs from 'dayjs';
import {config} from '../utils/config';

const environment = config.environment;

const rootUrl = 'https://app.datadoghq.com/logs';

export function dataDogShipmentQueryBuilder(
  referenceType: string,
  referenceValue: string
): string{
  const refValue = referenceValue.trim();

  let query = `%40env%3A${environment}%20`;

  switch (referenceType) {
    case 'PrimaryReference':
      query += `(primaryreference%3A${refValue.toLowerCase()} OR primary_reference%3A${refValue.toLowerCase()})`;
      break;
    case 'ShipmentID':
      query += `(shipmentid%3A${refValue.toLowerCase()} OR shipment_id%3A${refValue.toLowerCase()} )`;
      break;
    case 'CorrelationID':
      query += `(%40correlationID%3A${refValue}  OR correlation_id%3A${refValue})`;
      break;
    default:
  }

  const colParams =
    'service%2C%40http.status_code%2C%40content.requestName%2Cprimary_reference%2Cshipment_id%2C%40userName%2C%40content.response.timingMS%2C%40category';

  const now = dayjs();
  const start = dayjs().subtract(60, 'days');

  const startTime = start.valueOf();
  const stopTime = now.valueOf();

  const queryString = `${rootUrl}?cols=${colParams}&event&index=&live=true&messageDisplay=inline&query=${query}&stream_sort=desc&from_ts=${startTime}&to_ts=${stopTime}`;
  return queryString;
};

export function dataDogImporterQueryBuilder(importerName: string): string{
  const colParams = 'service%2Cimporter%2C%40content.fileName%2C%40content.hasIssues';

  const query = `%40env%3A${environment}%20service%3A%22Import+Customer+Integration%22+%40content.customerName%3A${importerName}&`;

  const now = dayjs();
  const start = dayjs().subtract(7, 'days');

  const startTime = start.valueOf();
  const stopTime = now.valueOf();

  const queryString = `${rootUrl}?cols=${colParams}&index=&live=true&messageDisplay=inline&query=${query}&stream_sort=desc&from_ts=${startTime}&to_ts=${stopTime}`;
  return queryString;
};
