import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid } from '@mui/material';

import { getImporterListApi, setImporterCanProcessApi, updateImporterApi } from '../../api/novaApi';
import { ImporterItem } from '../../models/schema';
import { ICToggleButtons } from './icToggleButtons';
import { ICTable } from './icTable';
import { EditImporterForm } from './editImporterForm';
import { useApi } from '../../hooks/useApi';
import { CommonError } from '../../common/commonError';
import { useAuth, USER_ROLES } from '../../auth';


export function ImportConfigPage() {
  const [pageError, setPageError] = useState<string>('');
  const [importerList, setImporterList] = useState<ImporterItem[]>([]);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [selectedImporter, setSelectedImporter] = useState<ImporterItem | undefined>(undefined);

  const getImportersRequest = useApi(getImporterListApi);
  const updateCanProcessRequest = useApi(setImporterCanProcessApi);


  const auth = useAuth();

  useEffect(() => {
    getImportersRequest.request();
  }, []);

  useEffect(() => {
    if(getImportersRequest.data){
      setImporterList(getImportersRequest.data);
    }
  }, [getImportersRequest.data]);
  
  async function canProcessAllHandler(canProcess: boolean){
    for (const importer of importerList) {
      if (importer.isActive) {
        const idsUser = await auth.actions.getUser();
        if(idsUser){
          let accessToken = idsUser.access_token;
          await setImporterCanProcessApi(accessToken,importer.name, canProcess);
        }
      }
    }
    getImportersRequest.request();
  };

  async function canProcessHandler(
    importerName: string,
    canProcess: boolean
  ){
    const idsUser = await auth.actions.getUser();
        if(idsUser){
          let accessToken = idsUser.access_token;
          await setImporterCanProcessApi(accessToken,importerName, canProcess);
        }
    getImportersRequest.request();
  };

  function onEditHandler(importer:string){
    const thisImporter = importerList.find(x=>x.name === importer);
    if(thisImporter){
      setSelectedImporter(thisImporter);
      setShowEditForm(true);
    }

  }

  async function onUpdateHandler(item:ImporterItem){

    const idsUser = await auth.actions.getUser();
    if(idsUser){
      let accessToken = idsUser.access_token;
      await updateImporterApi(accessToken,item);
    }

    setSelectedImporter(undefined);
    setShowEditForm(false);
    getImportersRequest.request();

  }

  function onCloseFormHandler(){
    setSelectedImporter(undefined);
    setShowEditForm(false);

  }

  function refreshHandler(){
    getImportersRequest.request();
  }

  function getMainForm(){
    return (<>
          {auth.user?.isAdmin && <ICToggleButtons onChange={canProcessAllHandler} onRefresh={refreshHandler} />}
          <ICTable importers={importerList} onCanProcess={canProcessHandler} onEdit={onEditHandler} />
          {selectedImporter && <EditImporterForm  importer={selectedImporter} open={showEditForm} onUpdate={onUpdateHandler} onClose={onCloseFormHandler} />}
    </>);
  }

  return (
    <Grid container spacing={2} direction='column'>
      <Grid container item>
        <Grid item xs={0}></Grid>
        <Grid item xs={10}>
          <h1>Import Config</h1>
          {getImportersRequest.error && <CommonError text={getImportersRequest.error} />}
          {getImportersRequest.loading && <CircularProgress/>}
          {!getImportersRequest.loading && importerList.length > 0 && getMainForm()}
         
        </Grid>

        <Grid item xs={1}></Grid>
      </Grid>
    </Grid>
  );
}
