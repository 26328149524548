import {Box, Card, CircularProgress, Grid} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth';
import { Formik, Form, Field } from 'formik';
import { StandardTextBox } from '../../common/standardTextBox';
import { StandardDropDown } from '../../common/standardDropDown';
import { StandardButton } from '../../common/standardButton';
import { useEffect, useState } from 'react';
import { EnterpriseItem } from '../../models/schema';
import { getItem, setItem } from '../../utils/cacheManager';
import { useApi } from '../../hooks/useApi';
import { getLegalEnterprisesApi } from '../../api/novaApi';

const dropDownItems = [ {id:'AccountNumber', name:'Account Number'},
                        {id:'EnterpriseName', name:'Enterprise Name'},
                        {id:'BusinessUnit', name:'Business Unit'},
                        {id:'GroupName', name:'Group Name'},
                        {id:'EnterpriseID', name:'Enterprise ID'}];

export interface FormValues {
referenceType: string;
referenceValue: string;
}

export function EnterpriseQueryPage() {

  const [enterpriseList, setEnterpriseList] = useState<EnterpriseItem[]>([]);
  const [filteredEnterpriseList, setFilteredEnterpriseList] = useState<EnterpriseItem[]>([]);


  const auth = useAuth();
  const navigate = useNavigate();
  const getEnterpriseRequest = useApi(getLegalEnterprisesApi);

  //initial load
  useEffect(() => {
    getEnterpriseList();
   }, []);

     //get enterprise list to populate the dropdown
  useEffect(() => {
    if(getEnterpriseRequest.data){
      setEnterpriseList(getEnterpriseRequest.data);
      setItem("enterpriseList",getEnterpriseRequest.data,600);
    }
  }, [getEnterpriseRequest.data]);

   
   function getEnterpriseList(){
    const storedEnterpriseList = getItem("enterpriseList");
    if(!storedEnterpriseList){
      getEnterpriseRequest.request();
    }
    else{
      setEnterpriseList(storedEnterpriseList);
    }
  }


  const getRequestForm = () => {
    return (

      <Formik

      initialValues={{
        referenceType:'EnterpriseName',
        referenceValue: ''
      }}

      validate={(values) => {
        const errors: Partial<FormValues> = {};
 
        if(!values.referenceValue){
            errors.referenceValue='Required';
        }else if(values.referenceValue.length < 3){
          errors.referenceValue='Data is too short';
        }

        return errors;
      }}

      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);

        const lowerCaseValue = values.referenceValue?.toLowerCase();

        if(values.referenceType === 'BusinessUnit'){
          const filterList = enterpriseList.filter(e => e.businessUnit?.toLowerCase() === lowerCaseValue);
          setFilteredEnterpriseList(filterList);
        }
        else if(values.referenceType === 'EnterpriseName'){
          const filterList = enterpriseList.filter(e => e.name.toLowerCase().startsWith(lowerCaseValue));
          setFilteredEnterpriseList(filterList);
        }
        else if(values.referenceType === 'AccountNumber'){
          const filterList = enterpriseList.filter(e => e.accountNumber?.toLowerCase() === lowerCaseValue);
          setFilteredEnterpriseList(filterList);
        }
        else if(values.referenceType === 'EnterpriseID'){
          const filterList = enterpriseList.filter(e => e.enterpriseId === parseInt(values.referenceValue));
          setFilteredEnterpriseList(filterList);
        }
        else if(values.referenceType === 'GroupName'){
          const filterList = enterpriseList.filter(e => e.groupName?.toLowerCase() === lowerCaseValue);
          setFilteredEnterpriseList(filterList);
        }
      }}

    >
      {({ submitForm, isSubmitting }) => (
        <Form>

            <Field
            component={StandardDropDown}
            name="referenceType"
            label="Reference Type"
            items={dropDownItems}
            width={300}
          />

            <Field
            component={StandardTextBox}
            name="referenceValue"
            label="Reference Value"
            width={340}
            maxLength={40}
          />

            <StandardButton onClick={submitForm}>GO</StandardButton>
            {isSubmitting && <CircularProgress />}
          
        </Form>
      )}
    </Formik>
    );
  };

  const getEnterpriseDetails = () => {
return(
<Box>
{filteredEnterpriseList && filteredEnterpriseList.map(e => <Box><Card sx={{padding:"10px", width:"800px"}} key={e.enterpriseId}>
  <span style={{color:'#2C3E50'}}>Name: </span><span style={{fontWeight:900}} >{e.name}</span><br/>
  <span style={{color:'#2C3E50'}}>Account: </span><span style={{fontWeight:900}} >{e.accountNumber}</span><br/>
  <span style={{color:'#2C3E50'}}>Business Unit: </span><span style={{fontWeight:900}} >{e.groupName}</span><br/>
  <span style={{color:'#2C3E50'}}>Group Name: </span><span style={{fontWeight:900}} >{e.groupName}</span><br/>
  <span style={{color:'#2C3E50'}}>EnterpriseId: </span><span style={{fontWeight:900}} >{e.enterpriseId}</span><br/>
  </Card><br/></Box>)}
</Box>
);
  }

  return (
    <Grid container spacing={2} direction='column'>
      <Grid container item>
        <Grid item xs={0}></Grid>
        <Grid item xs={10}>
          <h1>Enterprise Lookup</h1>
          {getRequestForm()}
          {getEnterpriseDetails()}
          
        </Grid>

        <Grid item xs={2}></Grid>
      </Grid>
    </Grid>
  );
}
