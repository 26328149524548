import {MenuItem, TextField} from "@mui/material";
import {FieldProps} from "formik";
import {FC, useMemo, useState} from "react";
import {DropDownItem} from "./commonDropDown";

export interface StandardDropDownProps{
    id:string,
    label:string,
    width?:number,
    readonly?:boolean,
    required?:boolean,
    items?: DropDownItem[]

}

export const StandardDropDown:FC<StandardDropDownProps & FieldProps> = ({field, form:{touched,errors}, ...props}) =>{

  const [isError, setIsError] = useState<boolean>(false);

  const determineError = useMemo(() =>{

    if(errors[field.name] && touched[field.name])
    {
      setIsError(true);
    }
    else{
      setIsError(false);
    }

  }
  ,[touched, errors]);


    return (
      <>
      <TextField select
                   id={props.id}
                   error={isError}
                   disabled={props.readonly}
                   label={props.label}
                   variant="outlined"
                   size="small"
                   style={{ width: props.width, marginRight: 10 }}
                   helperText={errors[field.name]?.toString()}
                   {...field}
                   >

                {props.items && props.items.map(option => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))}

        </TextField>
</>
    )

}
