import React, { useState } from 'react';
import generator from 'generate-password-ts';
import {
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  MenuItem,
  IconButton,
} from '@mui/material';
import { CommonButton } from '../../common/commonButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export function PasswordGeneratorPage() {
  const [includeSpecialChars, setIncludeSpecialChars] = useState<boolean>(false);
  const [passwordLength, setPasswordLength] = useState<number>(32);
  const [password, setPassword] = useState<string>('');

  function handleIncludeSpecialChars(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ){
    setIncludeSpecialChars(checked);
  };

  function submit() {
    const newPassword = generator.generate({
      length: passwordLength,
      numbers: true,
      symbols:includeSpecialChars,
      excludeSimilarCharacters:true,
      exclude:'{};:[]|/<>-_()+=\\\"^?~`\'.,'
    });

    setPassword(newPassword);
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(password);
  }

  const getForm = () => {
    return (
      <>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeSpecialChars}
                  onChange={handleIncludeSpecialChars}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label='Include Special Chars'
            />
          </FormGroup>
<br/>

      <Box>
      <TextField
          id="ddlKeyLength"
          select
          size="small"
          label="Select"
          value={passwordLength}
          helperText="Please select key length"
          onChange={e => setPasswordLength(Number(e.target.value))}
        >
            <MenuItem key="16" value={16}>16</MenuItem>
            <MenuItem key="32" value={32}>32</MenuItem>
          
        </TextField>
        </Box>

        <Box sx={{paddingTop:'15px', paddingBottom:'15px'}}>
           <CommonButton text='Generate' onClick={submit} />
        </Box>

        <Box>
        {password}    

        {   password && 
           <IconButton sx={{paddingLeft:'30px'}} onClick={copyToClipboard}>
           <ContentCopyIcon fontSize='small'/>
         </IconButton>
        }

        </Box>
      </>
    );
  };

  return (
    <Grid container spacing={2} direction='column'>
      <Grid container item>
        <Grid item xs={0}></Grid>
        <Grid item xs={10}>
          <h1>Generate API Key</h1>
          {getForm()}
        </Grid>

        <Grid item xs={2}></Grid>
      </Grid>
    </Grid>
  );
}
