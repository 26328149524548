import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { EditPushInvoiceConfigForm } from "./editPushInvoiceConfigForm";
import { StandardButton } from "../../../common/standardButton";
import { useEffect, useState } from "react";
import MapIcon from '@mui/icons-material/Map';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { EnterpriseInvoicePushConfigCommandItem, EnterpriseInvoicePushConfigItem } from "../../../models/schema";
import { addEnterpriseInvoicePushConfigApi, canProcessEnterpriseInvoicePushConfigApi, getEnterpriseInvoicePushConfigByEIDApi, getEnterpriseInvoicePushConfigByGroupApi, 
          updateEnterpriseInvoicePushConfigApi, updateEnterpriseInvoicePushTemplateApi } from "../../../api/novaApi";
import { useApi } from "../../../hooks/useApi";
import { EditPushInvoiceTemplateForm } from "./editPushInvoiceTemplateForm";

export interface PushInvoiceConfigDetailProps{
    identifierType:string,
    identifierValue:string,
}

export function PushInvoiceConfigDetail(props:PushInvoiceConfigDetailProps){

    const [invoicePushConfigList, setInvoicePushConfigList] = useState<EnterpriseInvoicePushConfigItem[]>([]);
    const [activeConfig, setActiveConfig] = useState<EnterpriseInvoicePushConfigItem | undefined>(undefined);

    const [showConfigForm, setShowConfigForm] = useState<boolean>(false);
    const [showTemplateForm, setShowTemplateForm] = useState<boolean>(false);
    const [pageError, setPageError] = useState<string>('');
    const [formError, setFormError] = useState<string>('');
    const [templateFormError, setTemplateFormError] = useState<string>('');

    const getInvoicePushRegistryGroupRequest = useApi(getEnterpriseInvoicePushConfigByGroupApi);
    const getInvoicePushRegistryEnterpriseRequest = useApi(getEnterpriseInvoicePushConfigByEIDApi);
    const addEnterpriseConfigRequest = useApi(addEnterpriseInvoicePushConfigApi);
    const updateEnterpriseConfigRequest = useApi(updateEnterpriseInvoicePushConfigApi);
    const updateEnterpriseTemplateRequest = useApi(updateEnterpriseInvoicePushTemplateApi);
    const updateCanProcessRequest = useApi(canProcessEnterpriseInvoicePushConfigApi);


    //initial load
    useEffect(() => {
      refreshPage();
    }, [props.identifierValue]);


    useEffect(() => {
      if(getInvoicePushRegistryGroupRequest.data){
        setInvoicePushConfigList(getInvoicePushRegistryGroupRequest.data);
      }
      
    }, [getInvoicePushRegistryGroupRequest.data]);

    useEffect(() => {
      if(getInvoicePushRegistryEnterpriseRequest.data){
        setInvoicePushConfigList(getInvoicePushRegistryEnterpriseRequest.data);
      }
      
    }, [getInvoicePushRegistryEnterpriseRequest.data]);

    useEffect(() => {
      if(updateCanProcessRequest.data){
        refreshPage();
      }
      
    }, [updateCanProcessRequest.data]);

    //add config
    useEffect(() => {
      if(addEnterpriseConfigRequest.data){
        refreshPage();
      }
      
    }, [addEnterpriseConfigRequest.data]);

    useEffect(() => {
      if(addEnterpriseConfigRequest.error){
        setFormError(addEnterpriseConfigRequest.error);
      }
      
    }, [addEnterpriseConfigRequest.error]);

    //update config
    useEffect(() => {
      if(updateEnterpriseConfigRequest.data){
        refreshPage();
      }
      
    }, [updateEnterpriseConfigRequest.data]);

    useEffect(() => {
      if(updateEnterpriseConfigRequest.error){
        setFormError(addEnterpriseConfigRequest.error);
      }
      
    }, [updateEnterpriseConfigRequest.error]);

    //update template
    useEffect(() => {
      if(updateEnterpriseTemplateRequest.data){
        refreshPage();
      }
      
    }, [updateEnterpriseTemplateRequest.data]);

    useEffect(() => {
      if(updateEnterpriseTemplateRequest.error){
        setTemplateFormError(addEnterpriseConfigRequest.error);
      }
      
    }, [updateEnterpriseTemplateRequest.error]);


    function displayAddForm(){
      
        const newConfig:EnterpriseInvoicePushConfigItem = {
          identifierType:props.identifierType,
          identifierValue:props.identifierValue,
          sequence:0,
          name:'',
          mapType:'STANDARD',
          connectionType:'EMAIL',
          mapName:'',
          canProcess:false,
          outputFormat:'CSV',
          isFinancialForce:false,
        };

        setActiveConfig(newConfig);
        setShowConfigForm(true);
    }

    function displayEditForm(e: React.SyntheticEvent<Element, Event>,identifierValue: string, sequence: number){

      //todo: need to pass in sequence too
      setShowConfigForm(true);
      setActiveConfig(invoicePushConfigList.find((item) => item.identifierValue === identifierValue && item.sequence === sequence));
    }

    function displayTemplateForm(e: React.SyntheticEvent<Element, Event>,identifierValue: string, mapName: string | undefined){
      setShowTemplateForm(true);
      setActiveConfig(invoicePushConfigList.find((item) => item.identifierValue === identifierValue && item.mapName === mapName));
    }

    function toggleCanProcess(e: React.SyntheticEvent<Element, Event>,identifierValue: string, sequence: number, canProcess: boolean){
      updateCanProcessRequest.request({name:identifierValue, sequence:sequence, canProcess:!canProcess});
      //todo: refresh page in response
    }

    function onCloseInvoicePushHandler(){
      resetPage();
  
    }
    
    function onUpdateInvoicePushHandler(isEdit:boolean, item:EnterpriseInvoicePushConfigCommandItem){

      if(isEdit){
        updateEnterpriseConfigRequest.request(item);
      }
      else{
        addEnterpriseConfigRequest.request(item);
      }

    }

    function onUpdateInvoiceTemplatePushHandler(identifer:string, mapName:string, template:string){

      const pushTemplateItem = {identifierValue:identifer, 
                                mapName:mapName, 
                                sheetlayout:template}

      updateEnterpriseTemplateRequest.request(pushTemplateItem);

    }

    function refreshPage(){
      resetPage();
      if(props.identifierType === 'GROUP'){
      
        getInvoicePushRegistryGroupRequest.request(props.identifierValue);
      }
      else if(props.identifierType === 'ENTERPRISE'){
        getInvoicePushRegistryEnterpriseRequest.request(props.identifierValue);
      }
      
    }

    function resetPage(){
      setShowConfigForm(false);
      setShowTemplateForm(false);
      setActiveConfig(undefined);
    }
        

    return(
        <Box>

    <StandardButton width={175} onClick={()=>displayAddForm()}>Add</StandardButton>
  
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>ID Type</TableCell>
          <TableCell>ID Value</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>---</TableCell>
          <TableCell>Can Process</TableCell>
          <TableCell>---</TableCell>
          <TableCell>---</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>

        {getInvoicePushRegistryGroupRequest.loading && <TableRow><TableCell colSpan={8}>Loading...</TableCell></TableRow>}
        {getInvoicePushRegistryEnterpriseRequest.loading && <TableRow><TableCell colSpan={8}>Loading...</TableCell></TableRow>}

        {invoicePushConfigList && invoicePushConfigList.map((row) => 

        <TableRow
              key={`${row.identifierValue} - ${row.sequence}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{row.identifierType}</TableCell>
              <TableCell>{row.identifierValue}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.mapType}</TableCell>
              <TableCell>{row.connectionType}</TableCell>
              <TableCell><IconButton onClick={(e) => toggleCanProcess(e,row.identifierValue, row.sequence, row.canProcess)}>
                              {displayBool(row.canProcess)}
                          </IconButton></TableCell>
              <TableCell>
                { row.mapType !== 'Basic' &&  <IconButton onClick={(e) => displayTemplateForm(e,row.identifierValue, row.mapName)}>
                    <MapIcon />  
                </IconButton>
                }
              </TableCell>
              <TableCell>
              <IconButton onClick={(e) => displayEditForm(e,row.identifierValue, row.sequence)}>
                <EditIcon />
                </IconButton>
                </TableCell>
        </TableRow>)}

        </TableBody>
      </Table>
    </TableContainer>

    {pageError && <div>{pageError}</div>}

    {activeConfig &&
          <EditPushInvoiceConfigForm 
              open={showConfigForm} 
              configItem={activeConfig} 
              error={formError}
              onClose={onCloseInvoicePushHandler} 
              onUpdate={onUpdateInvoicePushHandler}
            />
    }

   {activeConfig?.mapTemplate && 
            <EditPushInvoiceTemplateForm  
              open={showTemplateForm} 
              identifierValue={activeConfig.identifierValue} 
              mapName={activeConfig.mapName ?? ""} 
              mapTemplate={JSON.stringify(activeConfig.mapTemplate)}
              error={updateEnterpriseTemplateRequest.error}
              onClose={()=>setShowTemplateForm(false)}
              onUpdate = {onUpdateInvoiceTemplatePushHandler}
            />
    }

        </Box>
    );


}

function displayBool(isTrue:boolean){

    return (
      <strong>
        {isTrue && <CheckCircleIcon color='success' fontSize='large' />}
        {!isTrue && <CancelIcon sx={{ color: 'red' }} fontSize='large' />}
      </strong>
    );
  };

