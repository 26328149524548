import {Box, Grid} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import {GridColDef} from '@mui/x-data-grid-premium';
import React, {useEffect, useMemo, useState} from 'react';

import {DataGridPremium} from '@mui/x-data-grid-premium';

interface ShipmentDetailGridProps {
  tableData: any[];
  showTable?:boolean
}

const hiddenColumns = ['Table', 'ShipmentNumber', 'RowVersion', 'Note', 'id'];

export function ShipmentDetailGrid(props: ShipmentDetailGridProps){
  const [tableName, setTableName] = useState<string>('');
  const [numRecords, setNumRecords] = useState<number>(0);
  const [isTable, setIsTable] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [detailRows, setDetailRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);


  const generateColumns = (tableData: any): GridColDef[] => {
    const columns: GridColDef[] = [];

    for (const key in tableData) {
      if (!hiddenColumns.includes(key)) {
        const column: GridColDef = {
          field: key,
          headerName: key,
          resizable: true,
        };
        columns.push(column);
      }
    }

    return columns;
  };


  useMemo(() => {
    if (
      props.tableData &&
      props.tableData.length > 0 &&
      props.tableData[0].Table
    ) {
      setTableName(props.tableData[0].Table);
      setNumRecords(props.tableData.length);
      setIsTable(true);

      const generatedColumns = generateColumns(props.tableData[0]);
      setColumns(generatedColumns);

      const rowDataWithId = props.tableData.map((x, i) => {
        x.id = i + 1;
        return x;
      });

      setDetailRows(rowDataWithId);
    }
  }, [props.tableData,props.tableData.length,props.tableData[0].Table]);


  useMemo(() => {

    if(props.showTable !== undefined){
      setShowTable(props.showTable);
    }
    
  }, [props.showTable]);



  const showName = () => {
    return (
      <Grid item container alignItems='center' spacing={1}>
        <Grid item>
        <IconButton
          onClick={() => setShowTable(!showTable)}
          aria-label={showTable ? 'Hide table' : 'Show table'}
        >
          {showTable ? <IndeterminateCheckBoxOutlinedIcon /> : <AddBoxOutlinedIcon />}
        </IconButton>
        </Grid>

        <Grid item>{tableName}</Grid>

        <Grid item> ({numRecords})</Grid>
      </Grid>
    );
  };

  return (
    <Grid item>
      {isTable && showName()}
      {isTable && showTable && (
        <Box sx={{ width: 1200 }}>
          <DataGridPremium
            autoHeight={true}
            scrollbarSize={1}
            rows={detailRows}
            pageSize={15}
            pagination
            density='compact'
            columns={columns}
            getRowId={(row) => row.id}
          />
        </Box>
      )}
    </Grid>
  );
};
