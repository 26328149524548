import {Route, Routes} from 'react-router-dom';
import {PATHS} from './paths';

import {RequireAuth} from '../auth/requireAuth';
import {SigninCallback} from '../pages/auth/signInCallback';
import AddIntegrationPage from '../pages/integration/addIntegrationPage';
import {ImportConfigPage} from '../pages/integration/importConfigPage';
import {EnterpriseConfigPage} from '../pages/integration/enterpriseConfig/enterpriseConfigPage';
import {LogQueryPage} from '../pages/loqQuery/logQueryPage';
import {NotFoundPage} from '../pages/notFoundPage';
import {ShipmentDetailsPage} from '../pages/shipmentQuery/shipmentDetailsPage';
import {ShipmentQueryPage} from '../pages/shipmentQuery/shipmentQueryPage';
import {Base64Page} from '../pages/tools/base64Page';
import {FormatLogPage} from '../pages/tools/formatLogPage';
import {PasswordGeneratorPage} from '../pages/tools/passwordGeneratorPage';
import { EnterpriseQueryPage } from '../pages/enterprise/enterpriseQueryPage';
import { GroupConfigPage } from '../pages/integration/groupConfigPage';
import { CodeGeneratorPage } from '../pages/tools/codeGeneratorPage';

export function NovaRouter() {
  return (
    <Routes>
      <Route path={PATHS.SIGN_IN_CALLBACK} element={<SigninCallback />} />

      <Route element={<RequireAuth />}>
      <Route path={PATHS.HOME} element={<ShipmentQueryPage />} />
      <Route path={PATHS.SHIPMENT_QUERY} element={<ShipmentQueryPage />} />
      <Route path={PATHS.ENTERPRISE_LOOKUP} element={<EnterpriseQueryPage />} />
        <Route path={PATHS.SHIPMENT_DETAILS} element={<ShipmentDetailsPage />} />
        <Route path={PATHS.LOG_QUERY} element={<LogQueryPage />} />
        <Route path={PATHS.ADD_INTEGRATION} element={<AddIntegrationPage />} />
        <Route path={PATHS.IMPORT_CONFIG} element={<ImportConfigPage />} />
        <Route path={PATHS.TOOLS_BASE64} element={<Base64Page />} />
        <Route path={PATHS.TOOLS_FORMAT_LOG} element={<FormatLogPage />} />
        <Route path={PATHS.TOOLS_CODE_GENERATOR} element={<CodeGeneratorPage />} />
        <Route path={PATHS.TOOLS_PASS_GENERATOR} element={<PasswordGeneratorPage />} />
        <Route path={PATHS.CONFIG_ENTERPRISE} element={<EnterpriseConfigPage />} />
        <Route path={PATHS.CONFIG_GROUP} element={<GroupConfigPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
