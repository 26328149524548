import {Autocomplete, Box, Card, Grid,TextField} from '@mui/material';
import {useEffect, useState} from 'react';
import { getEnterpriseGroupsApi} from '../../api/novaApi';

import {useApi} from '../../hooks/useApi';
import {GroupItem} from '../../models/schema';

import { getItem, setItem } from '../../utils/cacheManager';
import { PushInvoiceConfigDetail } from './pushInvoiceConfigs/pushInvoiceConfigDetail';


export function GroupConfigPage() {

  const [pageError, setPageError] = useState<string>('');
  const [selectedGroup, setSelectedGroup] = useState<GroupItem | null>(null);
  const [groupList, setGroupList] = useState<GroupItem[]>([]);
  const getGroupRequest = useApi(getEnterpriseGroupsApi);

  
  //initial load
  useEffect(() => {
   getGroupList();
  }, []);


  //get enterprise list to populate the dropdown
  useEffect(() => {
    if(getGroupRequest.data){

      const sortedData = [...getGroupRequest.data].sort((a, b) => a.groupName.localeCompare(b.groupName));
      setGroupList(sortedData);
      setItem("groupList",sortedData,600);
    }
  }, [getGroupRequest.data]);


  function clear(){
    setSelectedGroup(null);
  }

  function getGroupList(){
    const storedGroupList = getItem("groupList");
    if(!storedGroupList){
      getGroupRequest.request();
    }
    else{
      setGroupList(storedGroupList);
    }
  }

  const changeGroup = (
    e: React.SyntheticEvent<Element, Event>,
    value: GroupItem | null
  ) => {
    if (value) {
      setSelectedGroup(value);
    }
    else{
      clear();
    }
  };

  function displayGroupSelect(){
    return (
    <Box sx={{padding:'15px'}}>
    <Autocomplete
      autoComplete
      includeInputInList
      id='enterprise'
      size="small"
      options={groupList}
      getOptionLabel={(option: GroupItem) => `${option.groupName}`}
      onChange={changeGroup}

      sx={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} label='Group' />
      )}
    />
  </Box>);
  }

  function displayGroupData(){
    return (
      <Box>
        <h3>Info</h3>

      <Card sx={{padding:'10px', width:"800px"}}>
     {selectedGroup && <Box >
      <span style={{color:'#2C3E50'}} >Name:</span> <span style={{fontWeight:900}} >{selectedGroup.groupName}</span> 
      &nbsp;&nbsp;&nbsp;
     <span style={{color:'#2C3E50'}} >Default Account #: </span> <span style={{fontWeight:900}} >{selectedGroup.accountNumber}</span>
     </Box>}
     </Card>

     {selectedGroup && selectedGroup.enterprises && selectedGroup.enterprises.length > 0 && 
      <Box>
        {selectedGroup.enterprises.map((e) => <Box>{e.enterpriseId}-{e.enterpriseName}</Box>)}
      </Box>
      }

      <h3>Push Invoice Registry</h3>
      {selectedGroup && <PushInvoiceConfigDetail identifierType='GROUP' identifierValue={selectedGroup.groupName} />}
   
    </Box>

    );
  }

  return (
    <Grid container spacing={2} direction='column'>
      <Grid container item>
        <Grid item xs={0}></Grid>
        <Grid item xs={10}>
          <Box sx={{backgroundColor:'yellow',marginTop:'15px', fontSize:'18px'}}><strong>DEMO Page</strong> </Box>
        <h1>Group Data</h1>
        {displayGroupSelect()}
        {selectedGroup && displayGroupData()}
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Grid>
  );
}
