import { useState } from "react";
import { useAuth, USER_ROLES } from '../auth';

export function useApi(apiFunc: any) {

    const [data, setData] = useState<any | undefined>(undefined);
    const [status, setStatus] = useState<number | undefined>(undefined);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const auth = useAuth();

    const request = async (...args: any[]) => {
        setLoading(true);
        setStatus(undefined);
        try {
            const idsUser = await auth.actions.getUser();
            console.log('get user in hook', idsUser);

            let accessToken = idsUser?.access_token;
            const result = await apiFunc(accessToken, ...args);

            if (result) {
                setStatus(200);
                setData(result);
            }

        } catch (error: any) {
            console.log(`There was an axios error`);
            console.log(`message ${error.message}`);
            console.log(`error.response.data ${error.response?.data}`);

            const statusCode = error.response?.status;
            console.log(`status code `, statusCode);

            if(statusCode && statusCode === 404){
                setError(`No data found`);
            }
            else if (error.response?.data?.errors) {
                setError(JSON.stringify(error.response.data.errors));
            }
            else if (error.message) {
                setError(error.message);
            }
            else {
                setError(JSON.stringify(error));
            }
        } finally {
            setLoading(false);
        }
    };

    return {
        status,
        data,
        error,
        loading,
        request
    };
};
