import {CircularProgress, Grid} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth';
import { Formik, Form, Field } from 'formik';
import { StandardTextBox } from '../../common/standardTextBox';
import { StandardDropDown } from '../../common/standardDropDown';
import { StandardButton } from '../../common/standardButton';
import { dataDogShipmentQueryBuilder } from '../../common/dataDogQueryBuilder';

const dropDownItems = [{id:'PrimaryReference', name:'PrimaryReference'},
                       {id:'ShipmentID', name:'ShipmentID'},
	                   {id:'CorrelationID', name:'CorrelationID'}];

export interface FormValues {
referenceType: string;
referenceValue: string;
}

export function LogQueryPage() {

  const auth = useAuth();
  const navigate = useNavigate();

  const getRequestForm = () => {
    return (
      
      <Formik

      initialValues={{
        referenceType:'PrimaryReference',
        referenceValue: ''
      }}

      validate={(values) => {
        const errors: Partial<FormValues> = {};
 
        if(!values.referenceValue){
            errors.referenceValue='Required';
        }else if(values.referenceValue.length < 4){
          errors.referenceValue='Data is too short';
        }

        return errors;
      }}

      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
         const url = dataDogShipmentQueryBuilder(values.referenceType, values.referenceValue);
         window.open(url,'_blank');
      }}

    >
      {({ submitForm, isSubmitting }) => (
        <Form>

            <Field
            component={StandardDropDown}
            name="referenceType"
            label="Reference Type"
            items={dropDownItems}
            width={300}
          />

            <Field
            component={StandardTextBox}
            name="referenceValue"
            label="Reference Value"
            width={340}
            maxLength={40}
          />

            <StandardButton onClick={submitForm}>GO</StandardButton>
            {isSubmitting && <CircularProgress />}
          
        </Form>
      )}
    </Formik>
    );
  };

  return (
    <Grid container spacing={2} direction='column'>
      <Grid container item>
        <Grid item xs={0}></Grid>
        <Grid item xs={10}>
          <h1>Log Request</h1>
          {getRequestForm()}
        </Grid>

        <Grid item xs={2}></Grid>
      </Grid>
    </Grid>
  );
}
