import { Box, IconButton, TextField } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState } from "react";

export interface CommonTextBoxProps{
    id:string,
    label:string,
    readonly?:boolean,
    hide?:boolean,
    error?:string,
    value:string,
    width?:number,
    email?:boolean,
    required?:boolean,
    maxLength?:number,
    onChange:(text:string) =>void
    onFocus?:(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) =>void
    onBlur?:(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) =>void

}

export function CommonTextBox(props:CommonTextBoxProps){
    
    const [textValue, setTextValue] = useState<string>(props.value);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');
    const [readonly, setReadonly] = useState<boolean>(props.readonly ?? false);
    const [hideText, setHideText] = useState<boolean>(props.hide ?? false);
    const [inputType, setInputType] = useState<string>('text');

    useEffect(() => {
      if (props.error) {
        setIsError(true);
        setErrorText(props.error);
      }
    }, [props.error]);

    function handleOnChange(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>)
    {
        console.log('handle OnChange');
        setTextValue(e.currentTarget.value);
        props.onChange(e.currentTarget.value);
    }

    function updateTextValue(newText:string){
        console.log('clear value');
        setTextValue(newText);
        props.onChange(newText);
    }

    function validate(textValue:string){
      let hasError = false;
      let errorMessage='';
      if(props.required && !textValue){
        hasError=true;
        errorMessage ='required'
      }
      else if(textValue && props.email)
      {
        const isValidEmail = validateEmail(textValue);
        if(!isValidEmail){
          hasError=true;
          errorMessage ='invalid email'
        }
      }

      setIsError(hasError);
      setErrorText(errorMessage);
    }

    function validateEmail(email:string){
      const isValid = /\S+@\S+\.\S+/.test(email);
      return isValid;
    }

    function showTextBox(){
      return ( <TextField
        id={props.id}
        label={props.label}
        variant='outlined'
        error={isError}
        type={inputType}
        helperText={errorText}
        value={textValue}
        style = {{width: props.width}}
        size='small'
        onFocus={props.onFocus}
        onChange={handleOnChange}
        InputProps={{ style: {backgroundColor:'white'},
          endAdornment: (
      <IconButton
        sx={{ visibility: textValue ? "visible" : "hidden" }}
        onClick={(e) => updateTextValue('')}
      >
        <ClearIcon />
      </IconButton>
          ),
        }}
        sx={{ "& .Mui-focused .MuiIconButton-root": { color: "primary.main" }}}
      />);
    }

    function showLabel(){
      return ( <Box>{textValue}</Box>)
    }

    return (
      <>
      {!readonly && showTextBox()}
      {readonly && showLabel()}
      </>
    )




}