import React from 'react';
import ReactDOM from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';
import { App } from './app';
import { AuthProvider } from './auth';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

LicenseInfo.setLicenseKey('3c9151a3a599afc3fbc79832cd73c305Tz05OTMzNixFPTE3NTk1ODc4ODEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=');

root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
      </AuthProvider>
  </React.StrictMode>
);
