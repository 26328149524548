import axios from 'axios';
import {P44TrackingItem} from '../models/p44Tracking';
import {
  CanPushInvoiceCommand,
  CreateIntegrationRequest,
  CreateIntegrationResponse,
  EnterpriseConfigItem,
  EnterpriseCredentialItem,
  EnterpriseInvoicePushConfigCommandItem,
  EnterpriseInvoicePushConfigItem,
  EnterpriseInvoicePushTemplateCommandItem,
  EnterpriseItem,
  EnterprisePushConfigItem,
  GroupItem,
  ImporterItem,
  ResponseItem,
} from '../models/schema';
import {config} from '../utils/config';

const API_ROOT = config.novaApiUrl;

export async function getLegalEnterprisesApi(accessToken:string): Promise<EnterpriseItem[]>{
  const url = `${API_ROOT}/api/enterprise`;
  const response = await axios.get(url,{timeout:60000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};

//Shipment Data

export async function getShipmentDetailsByPriRefApi(accessToken:string,primaryReference: string): Promise<any>{
  console.log(`Calling GetShipmentByPriRef ***`);
    const url = `${API_ROOT}/api/shipment/support-details?primaryReference=${primaryReference}`;
    const response = await axios.get(url,{timeout:30000, headers: { Authorization: `Bearer ${accessToken}` }});
    return response.data;
};

export async function getShipmentDetailsByShipmentIDApi(accessToken:string,shipmentId: string): Promise<any>{
  console.log(`Calling GetShipmentByShipmentID ***`);
  const url = `${API_ROOT}/api/shipment/support-details?shipmentId=${shipmentId}`;
  const response = await axios.get(url,{timeout:30000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};

//Importer

export async function getImporterListApi(accessToken:string): Promise<ImporterItem[]>{
  const url = `${API_ROOT}/api/integration/importer`;
  const response = await axios.get(url,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};

export async function setImporterCanProcessApi(accessToken:string,importerName: string,canProcess: boolean): Promise<void>{
  const data = { name: importerName, canProcess: canProcess };
  const url = `${API_ROOT}/api/integration/importer/can-process`;
  const result = await axios.put(url,data,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
};

export async function addImporterApi(accessToken:string,importer: ImporterItem): Promise<void>{
  const url = `${API_ROOT}/api/integration/importer`;
  const result = await axios.post(url,importer,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
};

export async function updateImporterApi(accessToken:string,importer: ImporterItem): Promise<void>{
  const url = `${API_ROOT}/api/integration/importer`;
  const result = await axios.put(url,importer,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
};

//Integration Credentials

export async function getEnterpriseCredentialsApi(accessToken:string): Promise<EnterpriseCredentialItem[]>{
  const url = `${API_ROOT}/api/integration/credential`;
  const response = await axios.get(url,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};

export async function createIntegrationUserApi(accessToken:string, request: CreateIntegrationRequest): Promise<CreateIntegrationResponse> {
  const url = `${API_ROOT}/api/integration/credential`;
  const response = await axios.post<CreateIntegrationResponse>(url, request,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};



// Enterprise Configs

export async function getEnterpriseConfigsApi(accessToken:string, enterpriseId:number): Promise<EnterpriseConfigItem[]>{
  const url = `${API_ROOT}/api/enterprise/config`;
  const response = await axios.get(url,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};

export async function getEnterpriseConfigByEIDApi(accessToken:string, enterpriseId:number): Promise<EnterpriseConfigItem[]>{
  const url = `${API_ROOT}/api/enterprise/${enterpriseId}/config`;
  const response = await axios.get(url,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};

export async function getEnterpriseConfigByGroupApi(accessToken:string, groupName:string): Promise<EnterpriseConfigItem[]>{
  const url = `${API_ROOT}/api/group/${groupName}/config`;
  const response = await axios.get(url,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};

//Groups

export async function getEnterpriseGroupsApi(accessToken:string): Promise<GroupItem[]>{
  const url = `${API_ROOT}/api/group`;
  const response = await axios.get(url,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};



// Enterprise Push Configs

export async function getEnterprisePushConfigsApi(accessToken:string, enterpriseId:number): Promise<EnterprisePushConfigItem[]>{
  const url = `${API_ROOT}/api/enterprise/push-config`;
  const response = await axios.get(url,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};

export async function getEnterprisePushConfigByEIDApi(accessToken:string, enterpriseId:number): Promise<EnterprisePushConfigItem[]>{
  const url = `${API_ROOT}/api/enterprise/${enterpriseId}/push-config`;
  const response = await axios.get(url,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};

export async function getEnterprisePushConfigByGroupApi(accessToken:string, groupName:string): Promise<EnterprisePushConfigItem[]>{
  const url = `${API_ROOT}/api/group/${groupName}/push-config`;
  const response = await axios.get(url,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};


// Enterprise Invoice Push Configs

export async function getEnterpriseInvoicePushConfigsApi(accessToken:string, enterpriseId:number): Promise<EnterpriseInvoicePushConfigItem[]>{
  const url = `${API_ROOT}/api/enterprise/invoice-push-config`;
  const response = await axios.get(url,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};

export async function getEnterpriseInvoicePushConfigByEIDApi(accessToken:string, enterpriseId:number): Promise<EnterpriseInvoicePushConfigItem[]>{
  const url = `${API_ROOT}/api/enterprise/${enterpriseId}/invoice-push-config`;
  const response = await axios.get(url,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};

export async function getEnterpriseInvoicePushConfigByGroupApi(accessToken:string, groupName:string): Promise<EnterpriseInvoicePushConfigItem[]>{
  const url = `${API_ROOT}/api/group/${groupName}/invoice-push-config`;
  const response = await axios.get(url,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};


export async function addEnterpriseInvoicePushConfigApi(accessToken:string, request: EnterpriseInvoicePushConfigCommandItem): Promise<ResponseItem> {
  const url = `${API_ROOT}/api/enterprise/invoice-push-config`;
  const response = await axios.post(url, request,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};

export async function updateEnterpriseInvoicePushConfigApi(accessToken:string, request: EnterpriseInvoicePushConfigCommandItem): Promise<ResponseItem> {
  const url = `${API_ROOT}/api/enterprise/invoice-push-config`;
  const response = await axios.put(url, request,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};

export async function updateEnterpriseInvoicePushTemplateApi(accessToken:string, request: EnterpriseInvoicePushTemplateCommandItem): Promise<ResponseItem> {
  const url = `${API_ROOT}/api/enterprise/invoice-push-template`;
  const response = await axios.put(url, request,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};

export async function canProcessEnterpriseInvoicePushConfigApi(accessToken:string, request: CanPushInvoiceCommand): Promise<ResponseItem> {
  const url = `${API_ROOT}/api/enterprise/invoice-push-config/can-process`;
  const response = await axios.put(url, request,{timeout:5000, headers: { Authorization: `Bearer ${accessToken}` }});
  return response.data;
};




