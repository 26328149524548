import {Box, Tab, Tabs} from "@mui/material";
import React from "react";
import {TabPanel} from "../../common/tabPanel";
import {ShipmentDetailTab} from "./shipmentDetailTab";

function a11yProps(index: number) {
    return {id: `simple-tab-${index}`,'aria-controls': `simple-tabpanel-${index}`};
}

export interface ShipmentDetailTabProps{
    primaryReference?:string,
    shipmentId?:string,
    shipmentDetails?:any[]
}

export function ShipmentDetailTabs(props:ShipmentDetailTabProps){

    const [tabValue, setTabValue] = React.useState(0);

    function handleChangeTabs(event: React.SyntheticEvent, newValue: number){
        setTabValue(newValue)
    };

    return(
       <Box sx={{marginTop:'40px'}}>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTabs} aria-label="simple tabs example">
                <Tab label="Details" {...a11yProps(0)} />
            </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
            {props.shipmentDetails && <ShipmentDetailTab shipmentDetails={props.shipmentDetails} />}
        </TabPanel>

        </Box>
    );
}
