import { AxiosRequestConfig } from "axios";

export const BGRequestConfig = (config: AxiosRequestConfig) => {
  const userDetails = localStorage.getItem('BGUser');
  if (!userDetails) return config;

  const {userName, userID, enterpriseID } = JSON.parse(userDetails);

  if (!userName || !userID || !enterpriseID) {
    return config;
  }

  if (config && config.headers) {
    config.headers["UserName"] = userName;
    config.headers["UserId"] = userID;
    config.headers["UserEnterpriseId"] = enterpriseID;
  }

  return config;
};

export const BGRequestError = (error: any) => {
  return Promise.reject(error);
};