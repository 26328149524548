import {
  CircularProgress, Dialog, DialogContent,
  DialogTitle, Grid
} from "@mui/material";
import * as yup from 'yup';
import {number, string} from 'yup';
import {useAuth} from '../../../auth';


import {Field, Form, Formik} from "formik";
import {StandardButton} from "../../../common/standardButton";
import {StandardCheckBox} from "../../../common/standardCheckBox";
import {StandardDropDown} from "../../../common/standardDropDown";
import {StandardTextBox} from "../../../common/standardTextBox";
import { EnterpriseInvoicePushConfigCommandItem, EnterpriseInvoicePushConfigItem } from "../../../models/schema";


const mapTypeDropDownItems = [{id:'BASIC', name:'BASIC'},
                              {id:'STANDARD', name:'STANDARD'},
                              {id:'CUSTOM', name:'CUSTOM'}];

const connectionTypeDropDownItems = [{id:'SFTP', name:'SFTP'},
                                 {id:'EMAIL', name:'EMAIL'}];

const outputFormatDropDownItems = [{id:'CSV', name:'CSV'},
                                  {id:'XLS', name:'XLS'},
                                  {id:'XLSX', name:'XLSX'}];
 

export interface FormValues {
  mapType: string;
  mapName: string;
  host: string;
  userName:string;
  password:string;
  port:string;
  connectionType:string;
  name:string;
  outputFormat:string;
  toRecipients:string;
  cCRecipients:string;
  bCCRecipients:string;
  isFinancialForce:boolean;
  canProcess:boolean;
 
  };

  const validationSchema = yup.object({
    //host:string().min(2,'too short').required('required'),
    //port:number().required('required').positive('cannot be a negative number').integer('must be an integer')

  });

export interface EditPushInvoiceConfigFormProps{
    open:boolean,
    configItem?:EnterpriseInvoicePushConfigItem,
    error?:string,
    onClose:()=>void,
    onUpdate:(isEdit:boolean, item:EnterpriseInvoicePushConfigCommandItem) => void
}



export function EditPushInvoiceConfigForm(props:EditPushInvoiceConfigFormProps){

  const auth = useAuth();

    function update(values: FormValues) {

        const updateImporter:EnterpriseInvoicePushConfigCommandItem = {
          identifierValue:props.configItem?.identifierValue ?? '',
          identifierType:props.configItem?.identifierType ?? '',
          sequence:props.configItem?.sequence ?? 0,
          mapType: values.mapType,
          mapName: values.mapName,
          host: values.host ?? '',
          connectionType: values.connectionType,
          name: values.name,
          outputFormat: values.outputFormat,
          userName: values.userName ?? '',
          port: values.port ? parseInt(values.port) : undefined,
          password: values.password ?? '',
          isFinancialForce: props.configItem?.isFinancialForce ?? false,
          canProcess: props.configItem?.canProcess ?? false,
          toRecipients: values.toRecipients ?? '',
          cCRecipients: values.cCRecipients ?? '',
          bCCRecipients: values.bCCRecipients ?? '',

        };

        const isEdit = !!(props.configItem && props.configItem.sequence > 0);

        props.onUpdate(isEdit,updateImporter);
    }

    function handleClose() {
        props.onClose();
    }

    return(
    <>
        <Dialog open={props.open} >
        <DialogTitle>{props.configItem?.identifierValue} Config Data</DialogTitle>

        <DialogContent>
        <Formik

initialValues={{
  mapType : props.configItem?.mapType ?? 'BASIC',
  mapName : props.configItem?.mapName ?? '',
  host : props.configItem?.host ?? '',
  userName : props.configItem?.userName ?? '',
  password : props.configItem?.password ?? '',
  port : props.configItem?.port ? props.configItem.port.toString() : '',
  connectionType  : props.configItem?.connectionType ?? 'EMAIL',
  outputFormat  : props.configItem?.outputFormat ?? 'CSV',
  name  : props.configItem?.name ?? '',
  toRecipients  : props.configItem?.toRecipients ?? '',
  cCRecipients  : props.configItem?.cCRecipients ?? '',
  bCCRecipients  : props.configItem?.bCCRecipients ?? '',
  isFinancialForce  : props.configItem?.isFinancialForce ?? false,
  canProcess  : props.configItem?.canProcess ?? false,
}}

validationSchema={validationSchema}

onSubmit={(values, { setSubmitting }) => {
  setSubmitting(false);
  update(values);
}}

>
{({ submitForm, isSubmitting, values }) => (
  <Form>
          <p>{props.error}</p>
        <Grid container spacing={2}>

        <Grid item xs={3}>
            Name
            </Grid>

            <Grid item xs={9}>
            <Field
            component={StandardTextBox}
            name="name"
            label="Name"
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>

            <Grid item xs={3}>
            Output Format
          </Grid>

            <Grid item xs={9}>
            <Field
            component={StandardDropDown}
            name="outputFormat"
            label="Output Format"
            items={outputFormatDropDownItems}
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>

            <Grid item xs={3}>
            Map Type
          </Grid>

            <Grid item xs={9}>
            <Field
            component={StandardDropDown}
            name="mapType"
            label="Map Type"
            items={mapTypeDropDownItems}
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>

            
            <Grid item xs={3}>
            Map Name
          </Grid>

          <Grid item xs={9}>
          <Field
            component={StandardTextBox}
            name="mapName"
            label="map Name"
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>

            <Grid item xs={3}>
            Conn Type
          </Grid>

            <Grid item xs={9}>
            <Field
            component={StandardDropDown}
            name="connectionType"
            label="Conn Type"
            items={connectionTypeDropDownItems}
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>



        {values.connectionType === 'SFTP' && 
        <>

          <Grid item xs={3}>
            Host
          </Grid>

          <Grid item xs={9}>
          <Field
            component={StandardTextBox}
            name="host"
            label="Host"
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>

            <Grid item xs={3}>
            UserName
            </Grid>

            <Grid item xs={9}>
            <Field
            component={StandardTextBox}
            name="userName"
            label="User Name"
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>

            <Grid item xs={3}>
            Password
            </Grid>

            <Grid item xs={9}>

            <Field
            component={StandardTextBox}
            name="password"
            label="Password"
            readonly={!auth.user?.isAdmin}
            isHidden={!auth.user?.isAdmin}
            width={375}
          />

            </Grid>

            <Grid item xs={3}>
            Port
            </Grid>

            <Grid item xs={9}>
            <Field
            component={StandardTextBox}
            name="port"
            label="Port"
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>

          </>}

        {values.connectionType === 'EMAIL' && 
        <>



            <Grid item xs={3}>
            To Email
            </Grid>

            <Grid item xs={9}>

            <Field
            component={StandardTextBox}
            name="toRecipients"
            label="To Email"
            readonly={!auth.user?.isAdmin}
            width={375}
          />
           </Grid>

           <Grid item xs={3}>
            CC Email
            </Grid>

            <Grid item xs={9}>

            <Field
            component={StandardTextBox}
            name="cCRecipients"
            label="CC Email"
            readonly={!auth.user?.isAdmin}
            width={375}
          />
           </Grid>

           <Grid item xs={3}>
            BCC Email
            </Grid>

            <Grid item xs={9}>

            <Field
            component={StandardTextBox}
            name="bCCRecipients"
            label="BCC Email"
            readonly={!auth.user?.isAdmin}
            width={375}
          />
           </Grid>


           </>}



           <Grid item xs={3}>
            Is Financial Force
            </Grid>

            <Grid item xs={9}>

            <Field
            type="checkbox"
            width={125}
            component={StandardCheckBox}
            name="isFinancialForce"
            readonly={!auth.user?.isAdmin}
            label=""
            
          />

            </Grid>

            <Grid item xs={3}>
            Can Process
            </Grid>

            <Grid item xs={9}>

            <Field
            type="checkbox"
            width={125}
            component={StandardCheckBox}
            name="canProcess"
            readonly={!auth.user?.isAdmin}
            label=""
            
          />

            </Grid>


            <Grid item container xs={12} justifyContent="flex-end">
            <Grid item><StandardButton hidden={!auth.user?.isAdmin} onClick={submitForm}>Update</StandardButton></Grid>
            <Grid item><StandardButton onClick={handleClose}>Cancel</StandardButton></Grid>
            {isSubmitting && <CircularProgress />}
            </Grid>
        </Grid>
        </Form>
)}
        </Formik>

        </DialogContent>
      </Dialog>

    </>
    );
}
