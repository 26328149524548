import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import type { } from '@mui/x-data-grid-premium/themeAugmentation';

// application global overrides on theme
const appTheme = responsiveFontSizes(
  createTheme({
    palette: {
      background: {
        paper: '#fff',
      },
      primary: { main: '#002E6D' },
      secondary: {main: '#fff'},
      error: {
        main: '#D72A2A',
      },
      warning: {
        main: '#FC7B09',
      },
      info: {
        main: '#6B7D6A',
      },
      success: {
        main: '#145A32',
      },
      text: {
        primary: '#111',
        secondary: '#bbb',
      },
    },
    typography: {
      fontFamily: 'Roboto',
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    components:{
      MuiOutlinedInput:{
        styleOverrides:{
          root:{
            backgroundColor:'white'
          }
        }
      },
      MuiDataGrid:{
        styleOverrides:{
          root:{
            backgroundColor:'white',
            fontSize:'12px',
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-colCell:focus-within,  & .MuiDataGrid-columnHeader:focus-within':
            {
              outline: 0,
            },
          }
        }
      }
    }
  })
);

export default appTheme;
