import React, { useState } from 'react';

import { IconButton, Menu, MenuItem, Avatar, Button } from '@mui/material';
import { BlueShipUser, useAuth, USER_ROLES } from '../../auth';

export function LogoutButton(){
    const [profileMenu, setProfileMenu] = React.useState<Element | null>(null);
    const auth = useAuth();

    function getLetter(userName?:string) {
        let letter = '';
        if (userName) {
          letter = userName.substring(0, 1);
        }
        return letter;
      }

      function getColor(user : BlueShipUser): string{
        let color='#F89623';
        if(user.isAdmin){
          color='#EE3A80';
        }
        else if (user.isSupport){
          color='#94C940';
        }
        return color;
      }

      //

    return(
        <div>
          {auth.user && 
             <IconButton onClick={(e) => setProfileMenu(e.currentTarget as Element)} color="inherit">
              <Avatar sx={{ bgcolor: getColor(auth.user)}}>{getLetter(auth.user.name)}</Avatar>
            </IconButton>}

            <Menu id='profile-menu'
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            disableAutoFocusItem>
                <MenuItem onClick={() => auth.actions.logout()}>LogOut</MenuItem>
                </Menu>

        </div>
    );
}
