import {
  Box, Container, CssBaseline, Divider, IconButton, List,
  ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar,
  Typography
} from '@mui/material';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import {styled, ThemeProvider} from '@mui/material/styles';
import {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {useAuth, USER_ROLES} from '../auth';
import {NovaRouter} from '../router/novaRouter';
import {PATHS} from '../router/paths';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArticleIcon from '@mui/icons-material/Article';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HandymanIcon from '@mui/icons-material/Handyman';
import InputIcon from '@mui/icons-material/Input';
import KeyIcon from '@mui/icons-material/Key';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MenuIcon from '@mui/icons-material/Menu';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import GroupIcon from '@mui/icons-material/Group';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';


import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import {LogoutButton} from '../pages/auth/logoutButton';
import appTheme from '../theme';


const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const Routes = [
  {
    path: PATHS.SHIPMENT_QUERY,
    sidebarName: 'Shipment Query',
    icon: <LocalShippingIcon />
  },
  {
    path: PATHS.ENTERPRISE_LOOKUP,
    sidebarName: 'Enterprise Lookup',
    icon: <ManageSearchIcon />
  },
  {
    path: PATHS.LOG_QUERY,
    sidebarName: 'Log Query',
    icon: <ArticleIcon />
  },
  {
    path: PATHS.IMPORT_CONFIG,
    sidebarName: 'Importers',
    icon: <InputIcon />
  },
  {
    path: PATHS.TOOLS_BASE64,
    sidebarName: 'Base 64 Encode',
    icon: <HandymanIcon />
  },
  {
    path: PATHS.TOOLS_FORMAT_LOG,
    sidebarName: 'Format Log',
    icon: <ArticleIcon />
  },
  {
    path: PATHS.TOOLS_PASS_GENERATOR,
    sidebarName: 'Password Generator',
    icon: <KeyIcon />
  },
  {
    path: PATHS.TOOLS_CODE_GENERATOR,
    sidebarName: 'Code Generator',
    icon: <DeveloperModeIcon />
  }
]

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export function MainAppBar() {
  const auth = useAuth();
  const [open, setOpen] = useState(false);



  const toggleDrawer = () => {
    setOpen(!open);
  }

  return (
    <ThemeProvider theme={appTheme}>
      <Box sx={{ display: 'flex', height: '100%' }}>
        <CssBaseline />
        <AppBar position='absolute' open={open}>
          <Toolbar sx={{ pr: '24px' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px', ...(open && { display: 'none' })
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Nova
            </Typography>
           <LogoutButton />
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>

          <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1] }}>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>

          <Divider />

          <List component="nav">

            {Routes.map((prop, key) => (
              <ListItem component={NavLink} to={prop.path} key={key} disablePadding>
                <ListItemButton>
                  <ListItemIcon children={prop.icon} />
                  <ListItemText sx={{color:(theme) => theme.palette.primary.main}} primary={prop.sidebarName} />
                </ListItemButton>
              </ListItem>
            ))}

            <Divider />

            { auth.userHasOperation(USER_ROLES.NOVA_ADMIN) && (
              <>
              <ListItem component={NavLink} to={PATHS.ADD_INTEGRATION} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <AddCircleIcon />
                  </ListItemIcon>
                  <ListItemText sx={{color:(theme) => theme.palette.primary.main}} primary="Create Integration" />
                </ListItemButton>
              </ListItem>

              </>
            )}

            { auth.userHasOperation(USER_ROLES.NOVA_ADMIN) && (
              <>
              <ListItem component={NavLink} to={PATHS.CONFIG_ENTERPRISE} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                  <GolfCourseIcon />
                  </ListItemIcon>
                  <ListItemText sx={{color:(theme) => theme.palette.primary.main}} primary="Enterprise Config" />
                </ListItemButton>
              </ListItem>

              </>
            )}

            { auth.userHasOperation(USER_ROLES.NOVA_ADMIN) && (
              <>
              <ListItem component={NavLink} to={PATHS.CONFIG_GROUP} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                  <GroupIcon />
                  </ListItemIcon>
                  <ListItemText sx={{color:(theme) => theme.palette.primary.main}} primary="Group Config" />
                </ListItemButton>
              </ListItem>

              </>
            )}


          </List>

        </Drawer>
        <Box component='main' sx={{ display: 'flex', paddingTop: '20px',
                            backgroundColor: (theme) => theme.palette.grey[100],
                          flexGrow: 1,
                          height: '100%',
                          overflow: 'auto' }}>

          <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
            <NovaRouter />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
