import { Box, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";
import { StandardButton } from "../../../common/standardButton";

export interface EditPushInvoiceTemplateFormProps{
    open:boolean;
    identifierValue:string;
    mapName:string;
    mapTemplate:string;
    error?:string;
    onUpdate:(identifer:string, mapName:string, template:string)=>void;
    onClose:()=>void;   

}

export function EditPushInvoiceTemplateForm(props:EditPushInvoiceTemplateFormProps){

    const replacer = (key:any, value:any) => (value === null ? undefined : value);

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isTable, setIsTable] = useState<boolean>(false);
    const [error, setError] = useState<string>(props.error ?? '');
    const [editTemplate, setEditTemplate] = useState<string>(stdJson(props.mapTemplate));

    function displayEditTemplate(){
        setIsEdit(true);
      }

      function closeEditTemplate(){
        props.onClose();
      }

      function updateEditTemplate(){
        props.onUpdate(props.identifierValue, props.mapName, editTemplate);
      }

      function cancelEditTemplate(){
        setEditTemplate(props.mapTemplate);
        setIsEdit(false);
      }

      function handleContentChange(e : React.ChangeEvent<HTMLInputElement>) {
        setEditTemplate(e.target.value);
      }

      function toggleView(){
        setIsTable(!isTable);
      }

      const toggleDisplay = isTable ? 'JSON View' : 'Table View';

return (
<Box sx={{ width: '2000px' }}>
<Dialog open={props.open} maxWidth={'lg'}>
        <DialogTitle>Template Data</DialogTitle>
        <DialogContent>
          {props.identifierValue && <Box sx={{color:'blue'}}>Group Name: {props.identifierValue}</Box>}
          {props.mapName && <Box sx={{color:'blue'}}>Map Name: {props.mapName}</Box>}
          {error && <Box sx={{color:'red'}}>{error}</Box>}

            {!isEdit && 
             <Box>
             <StandardButton width={175} onClick={displayEditTemplate}>Edit</StandardButton>
             <StandardButton width={175} onClick={toggleView}>{toggleDisplay}</StandardButton>
             <StandardButton width={175} onClick={closeEditTemplate}>Close</StandardButton>
             
            
              {!isTable  && 
             
                <Box sx={{minHeight:'600px',width:'1000px', backgroundColor:'white', border:'1px solid black', padding:'10px'}}>

                    <SyntaxHighlighter language='json' style={okaidia}>
                    {JSON.stringify(JSON.parse(props.mapTemplate), replacer, 2)}
                    </SyntaxHighlighter>

                </Box>
              }

              {isTable &&

                <Box sx={{minHeight:'600px',width:'1000px', backgroundColor:'white', border:'1px solid black', padding:'10px'}}>

<Box
                component="table"
                sx={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  '& th, & td': {
                    border: '1px solid black',
                    padding: '8px',
                  },
                }}
              >
                    <thead>
                        <tr>
                            <th>HeaderName</th>
                            <th>PropertyName</th>
                            <th>Is Ref</th>
                            <th>Multiple</th>
                            <th>DateFormat</th>
                            <th>NumberFormat</th>
                        </tr>
                    </thead>
                    <tbody>

                        {JSON.parse(editTemplate).sheets.map((sheet:any) => 
                            sheet.fields.map((field:any) => 
                                <tr>
                                    <td>{field.headerName ?? ''}</td>
                                    <td>{field.propertyName ?? ''}</td>
                                    <td>{field.isReference  ? 'true' : ''}</td>
                                    <td>{field.showMultiple ? 'true' : ''}</td>
                                    <td>{field.dateFormat ?? ''}</td>
                                    <td>{field.numberFormat ?? ''}</td>
                                </tr>
                            )
                        )}
                    </tbody>
                    </Box>
              


                </Box>
              }

          </Box>


            
            }

           

            {isEdit  && 
            <Box>
            <StandardButton width={175} onClick={updateEditTemplate}>Update</StandardButton>
            <StandardButton width={175} onClick={cancelEditTemplate}>Cancel</StandardButton>

            <Box sx={{minHeight:'600px',width:'1000px', backgroundColor:'white', border:'1px solid black', padding:'10px'}}>
                <TextField
                    fullWidth
                    multiline
                    minRows={10}
                    value= {editTemplate}
                    onChange={handleContentChange}
                    variant="outlined"
                    label="Editable JSON"
                />

                </Box>
            </Box>
            }



        </DialogContent>
        </Dialog>
    
</Box>);

}

  // Custom function to filter out null fields
  const filterNullFields = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map(filterNullFields);
    } else if (obj !== null && typeof obj === 'object') {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, value]) => value !== null)
          .map(([key, value]) => [key, filterNullFields(value)])
      );
    }
    return obj;
  };

  function stdJson(json:string){
    return JSON.stringify(filterNullFields(JSON.parse(json)), null, 2);
  }
