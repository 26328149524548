import axios from 'axios';
import { CurrentUser } from '../models/schema';
import { config } from '../utils/config';
import { BGRequestConfig, BGRequestError } from './shipmentApi.interceptors';

const shipmentApiClient = axios.create();
shipmentApiClient.interceptors.request.use(BGRequestConfig, BGRequestError);

const API_ROOT = config.shipmentApiUrl;

export async function getCurrentUser(accessToken:string,userName: string): Promise<CurrentUser>{
    const url = `${API_ROOT}/api/v1/user/current`;
    const response = await shipmentApiClient.get<CurrentUser>(url,{ headers: { UserName: userName, Authorization: `bearer ${accessToken}` }});
    return response.data;
  };