import React, { useState } from 'react';
import { Grid, Button, TextField, Box, CircularProgress, IconButton } from '@mui/material';
import { downloadBase64AsFileAsync } from '../../utils/binaryHelpers';
import { CommonError } from '../../common/commonError';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export function Base64Page() {
  const [pageError, setPageError] = useState<string>('');
  const [input, setInput] = useState<string>('');
  const [output, setOutput] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  function updateInput(
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ){
    setInput(e.currentTarget.value);
    setOutput('');
  };

  function encodeBase64(){
    try{

      if(!input){
        setPageError('There is no input to encode');
        return;
      }

      const base64 = window.btoa(input.trim());
      setOutput(base64);
    }
    catch(error){
      setOutput(JSON.stringify(error));
    }

  };

  function decodeBase64(){
    try{
      setPageError('');
      if(!input){
        setPageError('There is no input to decode');
        return;
      }

    const base64 = window.atob(input.trim());
    setOutput(base64);
  }
  catch(error){
    const testString='The string to be decoded is not correctly encoded';
    let errString = JSON.stringify(error);

    if(errString.includes(testString))
    {
      errString = testString;
    }
    setPageError(errString);
  }
  };

  async function decodeBase64Document(){
    try{

      setPageError('');
      if(!input){
        setPageError('There is no input to decode');
        return;
      }

      if(input.startsWith('JVBER')){
        setIsProcessing(true);
        const dataUrl=`data:application/pdf;base64,${input}`;
        setInput('');
        await downloadBase64AsFileAsync(dataUrl,'Sample.pdf');
        setIsProcessing(false);
      }
      else{
        setOutput('This is not a valid PDF file');
      }
      
  }
  catch(error){
    setOutput(JSON.stringify(error));
  }
  };

  function clearError(){
    setPageError('');
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(output);
  }

 function getBase64Form(){
    return (
<>
          <h3>Input</h3>
          <TextField
            placeholder='Enter Input'
            value={input}
            onChange={updateInput}
            onFocus={clearError}
            style = {{width: 1000}}
            InputProps={{ style: {backgroundColor:'white'},
            endAdornment: (
        <IconButton
          sx={{ visibility: input ? "visible" : "hidden" }}
          onClick={(e) => setInput('')}
        >
          <ClearIcon />
        </IconButton>
            ),
          }}
            multiline
            rows={5}
          />
          <br />
          <br />
        {!isProcessing && <Box>
            <Button variant='contained' onClick={encodeBase64} style={{marginRight:'20px'}}>
              Encode
            </Button>

            <Button variant='contained' onClick={decodeBase64} style={{marginRight:'20px'}}>
              Decode
            </Button>

            {/* <Button variant='contained' onClick={decodeBase64Document}>
              Decode PDF
            </Button> */}
          </Box>}

          {isProcessing && <CircularProgress />}

            <br />
          <br />


          {output && <><h3>Result</h3><Box>
            {output}  <IconButton sx={{paddingLeft:'30px'}} onClick={copyToClipboard}>
           <ContentCopyIcon fontSize='small'/>
         </IconButton>
            </Box></>}
</>
    );
  };

  return (
    <Grid container spacing={2} direction='column'>
      <Grid container item>
        <Grid item xs={0}></Grid>
        <Grid item xs={10}>
          <h1>Base 64 Tool</h1>
          <CommonError text={pageError} />
          {getBase64Form()}
        </Grid>

        <Grid item xs={2}></Grid>
      </Grid>
    </Grid>
  );
}
