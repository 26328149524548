import {Button} from "@mui/material";


export interface StandardButtonProps{
    children?: any;
    type?:'button' | 'reset' | 'submit',
    disabled?:boolean,
    hidden?:boolean,
    width?:number,
    padding?:number,
    onClick?:() =>void
}

export function StandardButton(props:StandardButtonProps){
    return (<>{!props.hidden &&
        <Button variant='contained'
        style={{margin: 5, width: props.width }}
        size='small'
        hidden={props.hidden}
        type={props.type}
        disabled={props.disabled}
        onClick={props.onClick}>
        {props.children}
        </Button>}
        </>
    );
}
