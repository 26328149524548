import dayjs from "dayjs";

interface CacheItem{
    created:Date,
    expires?:Date,
    content:any
}

export function getItem(keyName:string):any {
    let result = undefined;

    const cachedItem = sessionStorage.getItem(keyName);

    if(!cachedItem){
        return undefined;
    }

    const cachedResult = JSON.parse(cachedItem) as CacheItem;

        if(cachedResult.expires){
            const expireDate = dayjs(cachedResult.expires);
            const timeTilExpiration = expireDate.diff();

            if(timeTilExpiration > 0){
                result = cachedResult.content;
            }
            else
            {
                sessionStorage.removeItem(keyName);
            }
        }
        else{
            result = cachedResult.content;
        }
        
       return result;
}

export function setItem(keyName:string, content:any, ttlInSeconds?:number){

    try{
        const createDate = dayjs();

        const cachedItem:CacheItem = {
            created:createDate.toDate(),
            content:content
        }
    
        if(ttlInSeconds)
        {
            const dateExpires = createDate.add(ttlInSeconds,'s');
            cachedItem.expires = dateExpires.toDate();
        }
    
        sessionStorage.setItem(keyName, JSON.stringify(cachedItem));
    }
    catch{
        
    }
   
}