import dayjs from "dayjs";
import localForage from 'localforage';

interface CacheItem{
    created:Date,
    expires?:Date,
    content:any
}


export async function getItem(keyName:string): Promise<any> {
    let result = undefined;

    const cachedItem = await localForage.getItem<CacheItem>(keyName);

    if(!cachedItem){
        return undefined;
    }

        if(cachedItem.expires){
            const expireDate = dayjs(cachedItem.expires);
            const timeTilExpiration = expireDate.diff();

            if(timeTilExpiration > 0){
                result = cachedItem.content;
            }
            else
            {
                sessionStorage.removeItem(keyName);
            }
        }
        else{
            result = cachedItem.content;
        }
        
       return result;
}

export async function setItem(keyName:string, content:any, ttlInSeconds?:number){

    try{
        const createDate = dayjs();

        const cachedItem:CacheItem = {
            created:createDate.toDate(),
            content:content
        }
    
        if(ttlInSeconds)
        {
            const dateExpires = createDate.add(ttlInSeconds,'s');
            cachedItem.expires = dateExpires.toDate();
        }
    
        localForage.setItem(keyName, JSON.stringify(cachedItem));
    }
    catch{
        
    }
   
}