declare global {
    interface Window { MyConfig: any }
}

interface Config {
    identityServerUrl: string;
    shipmentApiUrl: string;
    novaApiUrl:string;
    environment:string;

}

export const config = window.MyConfig as Config;

