import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AccessDenied } from '../pages/auth/accessDenied';
import { useAuth } from './authContext';
import { setPostLoginUri } from './utilities';

type RequireAuthProps = {
  children?: JSX.Element;
  isAdmin?: boolean;
}

export const RequireAuth = ({ children, isAdmin }: RequireAuthProps) => {

  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false);
  const auth = useAuth();

  useEffect(() => {
    const serviceGetUser = async () => {
      let user = await auth.actions.getUser();
      if(user){
        console.log(`user`, user);
        setUserLoggedIn(true);
      }else{
        setPostLoginUri();
        auth.actions.login();
      }
    }

    serviceGetUser();

  });

  if(!auth.user){
    console.log(`auth user is null`);
    return null;
  }

  if(!auth.isValidUserType){
    console.log(`auth user is not valid type`);
    return <AccessDenied />;
  }

  return children ? children : <Outlet />;
};
