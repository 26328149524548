import React, { useState } from 'react';
import { Grid,  TextField, Box, IconButton } from '@mui/material';
import { CommonError } from '../../common/commonError';
import { StandardButton } from '../../common/standardButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import csv from "csvtojson";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark, materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import xmlFormat from 'xml-formatter';



export function FormatLogPage() {
  const [pageError, setPageError] = useState<string>('');
  const [input, setInput] = useState<string>('');
  const [output, setOutput] = useState<string>('');
  const [language, setLanguage] = useState<string>('');

  const replacer = (key:any, value:any) => (value === null ? undefined : value);

  const updateInput = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInput(e.currentTarget.value);
  };

  function copyToClipboard() {
    navigator.clipboard.writeText(output);
  }

  const formatJson = () => {
    clearError();

    if(!input){
      setPageError('There is no input to format');
    }

    setLanguage('json');
    setOutput(input);
  };

  const formatXml = () => {
    clearError();

    if(!input){
      setPageError('There is no input to format');
    }

    setLanguage('xml');
    setOutput(xmlFormat(input));
  };

  const formatTsv = () => {
    clearError();

    if(!input){
      setPageError('There is no input to format');
    }

    const mappedJson = tsvToJson(input);
    const jsonString = JSON.stringify(mappedJson);
    setOutput(jsonString);
  };

  async function formatCsv(){
    clearError();

    if(!input){
      setPageError('There is no input to format');
    }

    const mappedJson = await csvToJson(input);
    const jsonString = JSON.stringify(mappedJson);
    setOutput(jsonString);
  };

  const clear = () =>{
    setPageError('');
    setInput('');
    setOutput('');
  }

   function clearError(){
    setPageError('');
  }

  function tsvToJson(tsv: string){

    let result = [];

    const lines = input.split('\n');
    const headers = lines[0].split('~');

    for (let i = 1; i < lines.length-1; i++)
    {
      let obj: any = {};
      const rowItem = lines[i].split('~');
      for (let j = 0; j < rowItem.length; j++)
      {
        obj[headers[j]] = rowItem[j];
      }

      result.push(obj);
    }

    return result;
  }

  async function csvToJson(data: string){
    const jsonArray = await csv().fromString(data);
    return jsonArray;
  }

  const getFormatLog = () => {
    return (

      <Box sx={{display:'flex'}}>

        <Box sx={{width:'450px',padding:'10px'}}>
           <Box sx={{display:'flex'}}>
              <Box sx={{alignSelf:'baseline'}}><h3>Input</h3></Box>
              <Box sx={{alignSelf:'baseline'}}><IconButton onClick={clear}><CloseIcon /></IconButton></Box>
           </Box>

           <TextField
            placeholder='Enter Input'
            value={input}
            onChange={updateInput}
            onFocus={clearError}
            multiline
            rows={20}
            style={{ width: '400' }}
          />

           </Box>


        <Box sx={{width:'200px', padding:'100px 10px 10px'}}>

        <StandardButton width={175} onClick={formatJson}>
              Format JSON
            </StandardButton>

            <StandardButton width={175}  onClick={formatXml}>
              Format XML
            </StandardButton>

            <StandardButton width={175}  onClick={formatTsv}>
              TSV -&gt; JSON
            </StandardButton>

            <StandardButton width={175}  onClick={formatCsv}>
              CSV -&gt; JSON
            </StandardButton>

        </Box>

        <Box sx={{width:'630px',padding:'10px'}}>
          
        <Box sx={{display:'flex'}}>
          <h3>Result</h3>
          {output && <IconButton sx={{paddingLeft:'5px'}} onClick={copyToClipboard}>
           <ContentCopyIcon fontSize='small'/>
         </IconButton>}

          </Box>

        <Box sx={{minHeight:'600px',width:'600px', backgroundColor:'white', border:'1px solid black', padding:'10px'}}>
          {output && 

              <SyntaxHighlighter language={language} style={materialDark}>
              {JSON.stringify(JSON.parse(output), replacer, 2)}
              </SyntaxHighlighter>
          }
          
        </Box>
        </Box>

      </Box>
    );
  };

  return (
    <Grid container spacing={2} direction='column'>
      <Grid container item>
        <Grid item xs={0}></Grid>
        <Grid item xs={8}>
          <h1>Format Log</h1>
          <CommonError text={pageError} />
          {getFormatLog()}
        </Grid>

        <Grid item xs={2}></Grid>
      </Grid>
    </Grid>
  );
}