import {CircularProgress, Grid} from '@mui/material';
import dayjs from 'dayjs';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import { getShipmentDetailsByPriRefApi, getShipmentDetailsByShipmentIDApi} from '../../api/novaApi';
import {CommonError} from '../../common/commonError';
import {P44TrackingItem} from '../../models/p44Tracking';
import {ShipmentDetailTabs} from './shipmentDetailTabs';
import { useApi } from '../../hooks/useApi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export function ShipmentDetailsPage() {
  const [pageError, setPageError] = useState<string>('');
  const [shipmentDetails, setShipmentDetails] = useState<any[] | undefined>(undefined);
  const [shipmentId, setShipmentId] = useState<string>('');
  const [primaryReference, setPrimaryReference] = useState<string>('');
  const [status, setStatus]  = useState<string>('');
  const [dateCreated, setDateCreated]  = useState<string>('');
  const [enterprise, setEnterprise]  = useState<string>('');
  const [origin, setOrigin]  = useState<string>('');
  const [isTest, setIsTest]  = useState<boolean | null>(null);

  const [isLoading, setIsLoading]  = useState<boolean | null>(null);

  const { refType } = useParams<{ refType: string }>();
  const { refValue } = useParams<{ refValue: string }>();

  const getShipmentByIDRequest = useApi(getShipmentDetailsByShipmentIDApi);
  const getShipmentByPriRefRequest = useApi(getShipmentDetailsByPriRefApi);


  useEffect(() => {
    if (refValue && refType && refType === 'PrimaryReference') {

      if(!shipmentDetails){
        setIsLoading(true);
        getShipmentByPriRefRequest.request(refValue);
      }
      
   }
   else if (refValue && refType && refType === 'ShipmentID') {
    
     if(!shipmentDetails){
        setIsLoading(true);
        getShipmentByIDRequest.request(refValue);
     }
  }

  }, []);

  useEffect(() => {
    if(getShipmentByPriRefRequest.data){
      parseData(getShipmentByPriRefRequest.data);
      setIsLoading(false);
    }
  },[getShipmentByPriRefRequest.data]);


  useEffect(() => {
    if(getShipmentByIDRequest.data){
      parseData(getShipmentByIDRequest.data);
      setIsLoading(false);
    }
  },[getShipmentByIDRequest.data]);


  const displayBool = (isTrue:boolean| null) => {

    return (
      <strong>
        {isTrue && <CheckCircleIcon color='success' />}
        {!isTrue && isTrue !==null && <CancelIcon sx={{ color: 'red' }} />}
      </strong>
    );
  };

  function parseData(detailTables: any[]){

    const tableList:any[] = [];

    for(const table of detailTables){

      if(table.length > 0 && table[0].Table){

        if(table[0].Table === 'Shipment'){
          setShipmentId(table[0].ShipmentID);
          setStatus(table[0].Status);
          setIsTest(table[0].IsTest);
          setDateCreated(dayjs(table[0].DateCreated).format('MM/DD/YY HH:mm:ss'));
          setEnterprise(table[0].EnterpriseName);
          if(table[0].Origin && table[0].UserCreated){
            const mappedOrigin = mapOrigin(table[0].Origin,table[0].UserCreated);
            if(mappedOrigin){
              setOrigin(mappedOrigin);
            }
          }
        }

        if(table[0].Table === 'ShipmentReference'){
          const tableArray = table as any[];

          const primaryRef = tableArray.find(x=> x.IsPrimary === true );
          if(primaryRef){
            setPrimaryReference(primaryRef.Value);
          }
        }

        tableList.push(table);
      }
    }

    setShipmentDetails(tableList);

  }

  function mapOrigin(origin:string, userCreated:string){
    let newOrigin = origin;
    if(origin === 'BSWAPI'){
      if(userCreated.startsWith('EDI')){
        newOrigin = "EDI Integration"
      }
      else{
        newOrigin = "REST Integration"
      }

    }
    else if(origin === 'BSIMPORT'){
      newOrigin = "Importer Integration"
    }

    return newOrigin;
  }

  const getPageData = () => {
    return (
      <>
      <Grid container item direction='column' spacing={2}>
        <Grid container item spacing={3} alignItems="center">
          <Grid item><span style={{color:'#2C3E50'}} >ShipmentID</span> <span style={{fontWeight:900}} >{shipmentId}</span></Grid>
          <Grid item><span style={{color:'#2C3E50'}}  >Pri Ref</span> <span style={{fontWeight:900}} >{primaryReference}</span></Grid>
          <Grid item><span style={{color:'#2C3E50'}}  >Status</span> <span style={{fontWeight:900}} >{status}</span></Grid>
          <Grid item><span style={{color:'#2C3E50'}}  >Created</span><span style={{fontWeight:900}} > {dateCreated} (UTC)</span></Grid>
          <Grid item><span style={{color:'#2C3E50'}}  >Enterprise</span> <span style={{fontWeight:900}} >{enterprise}</span></Grid>
          <Grid item><span style={{color:'#2C3E50'}}  >Origin</span><span style={{fontWeight:900}} > {origin}</span></Grid>
          <Grid item><span style={{color:'#2C3E50',verticalAlign:'middle'}}  >Is Test</span><span style={{fontWeight:900,verticalAlign:'middle'}} > {isTest ? 'True' : 'False'}</span></Grid>
        </Grid>
        </Grid>

        {isLoading && <CircularProgress/>}

        {getShipmentByIDRequest.error && <CommonError text={getShipmentByIDRequest.error} />}
        {getShipmentByPriRefRequest.error && <CommonError text={getShipmentByPriRefRequest.error} />}

        <ShipmentDetailTabs shipmentDetails={shipmentDetails} 
                            primaryReference={primaryReference} 
                            shipmentId={shipmentId} />

       </>
    );
  };

  return (
    <Grid container spacing={2} direction='column'>
      <Grid container item>
        <Grid item xs={0}></Grid>
        <Grid item xs={10}>
          <br/>

          {getPageData()}

        </Grid>

        <Grid item xs={2}></Grid>
      </Grid>
    </Grid>
  );
}
