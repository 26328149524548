import {Checkbox, FormControlLabel, FormGroup, Tooltip} from "@mui/material";
import {FieldProps} from "formik";
import {FC} from "react";

export interface StandardCheckboxProps{
    id:string,
    label?:string,
    readonly?:boolean,
    tooltip?:string,
    width?:number
}

export const StandardCheckBox:FC<StandardCheckboxProps & FieldProps> = ({field, form:{touched,errors}, ...props}) =>{
    return (
      
        <FormGroup>
          <Tooltip title={props.tooltip} placement="top-start">
            <FormControlLabel style={{width:props.width}}
              control={
                <Checkbox
                disabled={props.readonly}
                id={props.id}
                {...field}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={props.label}
            />
            </Tooltip>
          </FormGroup>
         
    );
}
