import { Box } from "@mui/material";


export interface CommonErrorProps{
    text?:string
}

export function CommonError(props:CommonErrorProps){

    return(<Box sx={{display:'block', color:'red', height:'30px',padding:'10px'}}>
        {props.text}
    </Box>);
}