import {Box, Button, CircularProgress, Tooltip} from '@mui/material';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-mui';
import {AutoCompleteItem, StandardAutoComplete} from '../../common/standardAutoComplete';
import { StandardTextBox } from '../../common/standardTextBox';
import { EnterpriseItem } from '../../models/schema';
import { useEffect, useState } from 'react';
import { StandardCheckBox } from '../../common/standardCheckBox';

export interface Values {
  enterprise: string;
  userPrefix: string;
  email:string;
}

export interface AddIntegrationFormProps{
    submit:(prefix:string,email:string, isTest:boolean) =>void,
    clear:() => void
}

export function AddIntegrationForm(props:AddIntegrationFormProps) {

  return (
    <Formik
      initialValues={{
        userPrefix: '',
        email: '',
        isTest:false
      }}
      validate={(values) => {
        const errors: Partial<Values> = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        if(!values.userPrefix){
            errors.userPrefix='Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
          props.submit(values.userPrefix, values.email, values.isTest);


      }}
    >
      {({ submitForm, isSubmitting}) => (
        <Form>

          <Box sx={{padding:'15px',fontSize:'20px'}}>
          <Field
            component={StandardTextBox}
            id="userPrefix"
            name="userPrefix"
            type="userPrefix"
            label="userPrefix"
          /> WebSvcUser
           </Box>

           <Box sx={{padding:'15px'}}>
          <Field
             component={StandardTextBox}
             id="email"
            type="email"
            label="email"
            name="email"
            width={320}
            maxLength={40}
          />
          {isSubmitting && <CircularProgress />}
          </Box>

          
          <Box sx={{padding:'15px'}}>
         
          <Field
            type="checkbox"
            component={StandardCheckBox}
            width={100}
            id="isTest"
            name="isTest"
            label="Is Test"
            tooltip="Adds Demo Enterprise Role"
          />
          
          </Box>
          

          <Box sx={{width:'450px'}}>
           <Box sx={{display:'flex'}}>

         <Box sx={{padding:'15px'}} >
          <Button
          id="submit"
          name="submit"
            variant="contained"
            color="primary"
            size='small'
            disabled={isSubmitting}
            onClick={submitForm}
          >
            Create
          </Button>
          </Box>

          <Box sx={{padding:'15px'}}>
          <Button type='reset'
            variant='contained'
            color="primary"
            size='small'
            onClick={props.clear}
          >
            Clear
          </Button>
          </Box>
          </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
