import {
  CircularProgress, Dialog, DialogContent,
  DialogTitle, Grid
} from "@mui/material";
import * as yup from 'yup';
import {number, string} from 'yup';
import {useAuth} from '../../auth';
import {ImporterItem} from "../../models/schema";

import {Field, Form, Formik} from "formik";
import {StandardButton} from "../../common/standardButton";
import {StandardCheckBox} from "../../common/standardCheckBox";
import {StandardDropDown} from "../../common/standardDropDown";
import {StandardTextBox} from "../../common/standardTextBox";


const adapterDropDownItems = [{id:'FTP', name:'FTP'},
                              {id:'IMAP', name:'IMAP'},
                              {id:'Outlook365', name:'Outlook365'},
                              {id:'SFTP', name:'SFTP'}];

export interface FormValues {
  isActive:boolean;
  adapterType: string;
  host: string;
  userName:string;
  password:string;
  port:string;
  bookReqPath:string;
  bookRespPath:string;
  errorPath:string;
  errorEmail:string;
  sendResponse:boolean;
  apiUserName:string;
  apiPassword:string;
  };

  const validationSchema = yup.object({
    host:string().min(2,'too short').required('required'),
    port:number().required('required').positive('cannot be a negative number').integer('must be an integer')

  });

export interface EditImporterFormProps{
    open:boolean,
    importer:ImporterItem,
    onClose:()=>void,
    onUpdate:(item:ImporterItem) => void
}

export function EditImporterForm(props:EditImporterFormProps){

  const auth = useAuth();

    function update(values: FormValues) {

        const updateImporter:ImporterItem = {
          name: props.importer.name,
          adapterType: values.adapterType,
          canProcess: props.importer.canProcess,
          isActive: values.isActive,
          sendResponse: values.sendResponse,
          bookRequestPath: values.bookReqPath,
          bookResponsePath: values.bookRespPath,
          errorPath:values.errorPath,
          host: values.host,
          apiUser: values.apiUserName,
          apiPassword: values.apiPassword,
          userName: values.userName,
          port: values.port ? parseInt(values.port) : undefined,
          password: values.password,
          errorEmail: values.errorEmail
        };

        props.onUpdate(updateImporter);
    }

    function handleClose() {
        props.onClose();
    }

    return(
    <>
        <Dialog open={props.open}   >
        <DialogTitle>{props.importer.name} Importer Data</DialogTitle>

        <DialogContent>
        <Formik

initialValues={{
  isActive:props.importer.isActive,
  adapterType: props.importer.adapterType,
  host: props.importer.host,
  userName:props.importer.userName,
  password:props.importer.password,
  port:props.importer.port?.toString() ?? '',
  bookReqPath:props.importer.bookRequestPath,
  bookRespPath:props.importer.bookResponsePath,
  errorPath:props.importer.errorPath,
  errorEmail:props.importer.errorEmail,
  sendResponse:props.importer.sendResponse,
  apiUserName:props.importer.apiUser,
  apiPassword:props.importer.apiPassword
}}

validationSchema={validationSchema}

onSubmit={(values, { setSubmitting }) => {
  setSubmitting(false);
  update(values);
}}

>
{({ submitForm, isSubmitting }) => (
  <Form>

        <Grid container spacing={2}>

        <Grid item xs={3}>
            Is Active
            </Grid>

            <Grid item xs={9}>
            <Field
            type="checkbox"
            width={125}
            component={StandardCheckBox}
            name="isActive"
            readonly={!auth.user?.isAdmin}
            label="Is Active"
            tooltip="Indicates whether this importer is actively being used by customer"
          />
            </Grid>


        <Grid item xs={3}>
            Adapter Type
          </Grid>

            <Grid item xs={9}>
            <Field
            component={StandardDropDown}
            name="adapterType"
            label="Adapter Type"
            items={adapterDropDownItems}
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>

          <Grid item xs={3}>
            Host
          </Grid>

          <Grid item xs={9}>
          <Field
            component={StandardTextBox}
            name="host"
            label="Host"
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>

            <Grid item xs={3}>
            UserName
            </Grid>

            <Grid item xs={9}>
            <Field
            component={StandardTextBox}
            name="userName"
            label="User Name"
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>

            <Grid item xs={3}>
            Password
            </Grid>

            <Grid item xs={9}>

            <Field
            component={StandardTextBox}
            name="password"
            label="Password"
            readonly={!auth.user?.isAdmin}
            isHidden={!auth.user?.isAdmin}
            width={375}
          />

            </Grid>

            <Grid item xs={3}>
            Port
            </Grid>

            <Grid item xs={9}>
            <Field
            component={StandardTextBox}
            name="port"
            label="Port"
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>

            <Grid item xs={3}>
            Book Req Path
            </Grid>

            <Grid item xs={9}>

            <Field
            component={StandardTextBox}
            name="bookReqPath"
            label="Book Req Path"
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>

            <Grid item xs={3}>
            Book Resp Path
            </Grid>

            <Grid item xs={9}>

            <Field
            component={StandardTextBox}
            name="bookRespPath"
            label="Book Resp Path"
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>

            <Grid item xs={3}>
            Error Path
            </Grid>

            <Grid item xs={9}>

            <Field
            component={StandardTextBox}
            name="errorPath"
            label="Error Path"
            readonly={!auth.user?.isAdmin}
            width={375}
          />

            </Grid>

            <Grid item xs={3}>
            Error Email
            </Grid>

            <Grid item xs={9}>

            <Field
            component={StandardTextBox}
            name="errorEmail"
            label="Error Email"
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>

            <Grid item xs={3}>
            Send Response
            </Grid>

            <Grid item xs={9}>

            <Field
            type="checkbox"
            width={125}
            component={StandardCheckBox}
            name="sendResponse"
            readonly={!auth.user?.isAdmin}
            label="SendResponse"
            tooltip="Set to return a response CSV file"
          />

            </Grid>

            <Grid item xs={3}>
            Api Username
            </Grid>

            <Grid item xs={9}>

            <Field
            component={StandardTextBox}
            name="apiUserName"
            label="Api UserName"
            readonly={!auth.user?.isAdmin}
            width={375}
          />
            </Grid>

            <Grid item xs={3}>
            Api Password
            </Grid>

            <Grid item xs={9}>

            <Field
            component={StandardTextBox}
            name="apiPassword"
            label="Api Password"
            readonly={!auth.user?.isAdmin}
            isHidden={!auth.user?.isAdmin}
            width={375}
          />

            </Grid>
            <Grid item container xs={12} justifyContent="flex-end">
            <Grid item><StandardButton hidden={!auth.user?.isAdmin} onClick={submitForm}>Update</StandardButton></Grid>
            <Grid item><StandardButton onClick={handleClose}>Cancel</StandardButton></Grid>
            {isSubmitting && <CircularProgress />}
            </Grid>
        </Grid>
        </Form>
)}
        </Formik>

        </DialogContent>
      </Dialog>

    </>
    );
}
