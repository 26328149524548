import React, { useState } from 'react';
import { Grid, Button, TextField, Box, IconButton, FormControl, RadioGroup, FormLabel, FormControlLabel, Radio } from '@mui/material';
import JSONPretty from 'react-json-pretty';
import XMLViewer from 'react-xml-viewer-2'
import { CommonError } from '../../common/commonError';
import { StandardButton } from '../../common/standardButton';
import { jsonFormatTheme } from '../../jsonFormatTheme';
import CloseIcon from '@mui/icons-material/Close';
import csv from "csvtojson";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark, materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import {  cSharpClassToSchemaList, generateCSharpClassFromSchema, generateCSharpMapperClassFromSchema, generateJsonFromSchema, generateTypescriptInterfaceFromSchema, generateTypeScriptMapperClassFromSchema, jsonToSchemaList, typescriptInterfaceToSchemaList } from '../../utils/codeGenerator';


export function CodeGeneratorPage() {
  const [pageError, setPageError] = useState<string>('');
  const [input, setInput] = useState<string>('');

  const [output, setOutput] = useState<string>('');
  const [language, setLanguage] = useState<string>('');

  const [inputType, setInputType] = useState('json');
  const [outputType, setOutputType] = useState('csharp');


  //update the text from the input box
  const updateInput = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInput(e.currentTarget.value);
  };

    // Event handler for "Input" radio group
    const handleInputTypeChange = (e : React.ChangeEvent<HTMLInputElement>) => {
      setInputType(e.target.value);
    };
  
    // Event handler for "Output" radio group
    const handleOutputTypeChange = (e : React.ChangeEvent<HTMLInputElement>) => {
      setOutputType(e.target.value);
    };


  //generate the output code and set the language of for the display viewer   
  function generateCode(){

    clearOutput();

    if(!input){
      setPageError('There is no input to format');
    }

    let schemaList = null;

    if(inputType === 'json'){
      schemaList = jsonToSchemaList('MyClass', input);
    }
    else if(inputType === 'csharp'){
      schemaList = cSharpClassToSchemaList(input);
    }
    else if(inputType === 'typescript'){
      schemaList = typescriptInterfaceToSchemaList(input);
    }

    if(!schemaList){
      setPageError('There was an error parsing the input');
      return;
    }

    if(outputType === 'json'){
      setOutput(generateJsonFromSchema(schemaList));
      setLanguage('json');
    }
    else if(outputType === 'csharp'){
      setOutput(generateCSharpClassFromSchema(schemaList));
      setLanguage('csharp');
    }
    else if(outputType === 'typescript'){
      setOutput(generateTypescriptInterfaceFromSchema(schemaList));
      setLanguage('typescript');
    }
    else if(outputType === 'csharp_mapper'){
      setOutput(generateCSharpMapperClassFromSchema(schemaList));
      setLanguage('csharp');
    }
    else if(outputType === 'typescript_mapper'){
      setOutput(generateTypeScriptMapperClassFromSchema(schemaList));
      setLanguage('typescript');
    }

    

  }

  const clear = () =>{
    setInput('');
    clearOutput();
  }

  const clearOutput = () =>{
    setPageError('');
    setOutput('');
  }

   function clearError(){
    setPageError('');
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(output);
  }


  const getFormatLog = () => {
    return (

      <Box sx={{display:'flex'}}>

        <Box sx={{width:'450px',padding:'10px'}}>
           <Box sx={{display:'flex'}}>
              <Box sx={{alignSelf:'baseline'}}><h3>Input</h3></Box>
              <Box sx={{alignSelf:'baseline'}}><IconButton onClick={clear}><CloseIcon /></IconButton></Box>
           </Box>

           <TextField
            placeholder='Enter Input'
            value={input}
            onChange={updateInput}
            onFocus={clearError}
            multiline
            rows={20}
            style={{ width: '400' }}
          />

           </Box>


        <Box sx={{width:'200px', padding:'100px 10px 10px'}}>

        <FormControl>
        <FormLabel id="input-radio-buttons-group-label">Input</FormLabel>
        <RadioGroup
          aria-labelledby="input-radio-buttons-group-label"
          defaultValue="json"
          name="radio-buttons-group"
          value={inputType} // Set value to state variable
          onChange={handleInputTypeChange} // Set onChange to event handler
        >
          <FormControlLabel value="json" control={<Radio />} label="Json" />
          <FormControlLabel value="csharp" control={<Radio />} label="C# Class" />
          <FormControlLabel value="typescript" control={<Radio />} label="TS Interface" />
        </RadioGroup>
      </FormControl>

      <br/><br/>

      <FormControl>
        <FormLabel id="input-radio-buttons-group-label">Output</FormLabel>
        <RadioGroup
          aria-labelledby="input-radio-buttons-group-label"
          defaultValue="csharp"
          name="radio-buttons-group"
          value={outputType} // Set value to state variable
          onChange={handleOutputTypeChange} // Set onChange to event handler
        >
          <FormControlLabel value="json" control={<Radio />} label="Json" />
          <FormControlLabel value="csharp" control={<Radio />} label="C# Class" />
          <FormControlLabel value="typescript" control={<Radio />} label="TS Interface" />
          <FormControlLabel value="csharp_mapper" control={<Radio />} label="C# Mapper" />
          <FormControlLabel value="typescript_mapper" control={<Radio />} label="TS Mapper" />
        </RadioGroup>
      </FormControl>

      <br/><br/>

      <StandardButton width={175} onClick={generateCode}>Generate</StandardButton>

        </Box>

        <Box sx={{width:'630px',padding:'10px'}}>
          
        <Box sx={{display:'flex'}}>
          <h3>Result</h3> 
        
        {output && <IconButton sx={{paddingLeft:'5px'}} onClick={copyToClipboard}>
           <ContentCopyIcon fontSize='small'/>
         </IconButton>}
         </Box>

        <Box sx={{minHeight:'600px',width:'600px', backgroundColor:'white', border:'1px solid black', padding:'10px'}}>
          {output && 
          
          <SyntaxHighlighter language={language} style={materialDark}>
            {output}
          </SyntaxHighlighter>
          
          }
          
        </Box>
        </Box>

      </Box>
    );
  };

  return (
    <Grid container spacing={2} direction='column'>
      <Grid container item>
        <Grid item xs={0}></Grid>
        <Grid item xs={8}>
          <h1>Code Generator</h1>
          <CommonError text={pageError} />
          {getFormatLog()}
        </Grid>

        <Grid item xs={2}></Grid>
      </Grid>
    </Grid>
  );
}

