import {
  Box,  Card, CardContent,  CircularProgress,Grid, TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, {useEffect, useState} from 'react';
import {createIntegrationUserApi, getLegalEnterprisesApi} from '../../api/novaApi';
import {CommonError} from '../../common/commonError';
import {useApi} from '../../hooks/useApi';
import {
  CreateIntegrationRequest,
  CreateIntegrationResponse,
  EnterpriseItem
} from '../../models/schema';
import { AddIntegrationForm } from './addIntegrationForm';
import { useEnterpriseCacheApi } from '../../hooks/useEnterpriseCacheApi';


function AddIntegrationPage() {
  const [pageError, setPageError] = useState<string>('');

  const [selectedEnterprise, setSelectedEnterprise] = useState<EnterpriseItem | null>(null);
  const [missingEIDError, setMissingEIDError] = useState<boolean>(false);
  const [missingEIDMessage, setMissingEIDMessage] = useState<string>('');


  const [enterpriseList, setEnterpriseList] = useState<EnterpriseItem[]>([]);

  const [newIntegrationResponse, setNewIntegrationResponse] =
    useState<CreateIntegrationResponse | null>(null);

    const getEnterpriseRequest = useEnterpriseCacheApi(getLegalEnterprisesApi);
    const createIntegrationRequest = useApi(createIntegrationUserApi);

  //retrieve enterprise list  
  useEffect(() => {
    console.log('get enterprise list');
    getEnterpriseRequest.request();
  }, []);

  useEffect(() => {
        if(getEnterpriseRequest.data){
          console.log('enterprise list', getEnterpriseRequest.data);
          setEnterpriseList(getEnterpriseRequest.data);
        }

  }, [getEnterpriseRequest.data]);

  useEffect(() => {
   if(createIntegrationRequest.data){
    setNewIntegrationResponse(createIntegrationRequest.data);
   }
  }, [createIntegrationRequest.data]);

  useEffect(() => {
    if(createIntegrationRequest.error){
      setPageError(createIntegrationRequest.error);
    }
   }, [createIntegrationRequest.error]);

  const changeEnterprise = (
    e: React.SyntheticEvent<Element, Event>,
    value: EnterpriseItem | null
  ) => {
    if (value) {
      setSelectedEnterprise(value);
      setMissingEIDError(false);
      setMissingEIDMessage('');
    }
    else{
      clear();
    }
  };


  function submitForm(userPrefix:string, email:string, isTest:boolean){

    setPageError('');

    if(!selectedEnterprise){
      setMissingEIDError(true);
      setMissingEIDMessage('Required');
      return;
    }
    else
    {
      setMissingEIDError(false);
      setMissingEIDMessage('');
    }

    const newIntegrationUserCommand : CreateIntegrationRequest = {
      enterpriseID: selectedEnterprise.enterpriseId,
      enterpriseName: selectedEnterprise.name,
      accountNumber: selectedEnterprise.accountNumber,
      userPrefix: userPrefix,
      email: email,
      isTestAccount: isTest,
    };

    createIntegrationRequest.request(newIntegrationUserCommand);
  }

  function clear(){
    setSelectedEnterprise(null);
    setMissingEIDError(false);
    setMissingEIDMessage('');
    setPageError('');
  }

  const getForm = () => {
    return (
      <>
      <Box sx={{padding:'15px'}}>
        
          <Autocomplete
            autoComplete
            includeInputInList
            value={selectedEnterprise}
            id='enterprise'
            size="small"
            options={enterpriseList}
            getOptionLabel={(option: EnterpriseItem) => `${option.name}-${option.accountNumber}`}

            onChange={changeEnterprise}

            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField {...params} error={missingEIDError} helperText={missingEIDMessage} label='Enterprise' />
            )}
          />
        </Box>
        <AddIntegrationForm submit={submitForm} clear={clear} />
          </>
    );
  };

  const getResult = () => {
    return (
      <Card sx={{ width: 825, mt: 5, backgroundColor: '#dddddd' }}>
        <CardContent>
          <Box>ClientId: {newIntegrationResponse?.clientID} </Box>
          <Box>Client Secret: {newIntegrationResponse?.secret} </Box>
          <Box>Email: {newIntegrationResponse?.emailAddress} </Box>
          <Box>EnterpriseId: {newIntegrationResponse?.enterpriseID} </Box>
          <Box>Account: {newIntegrationResponse?.accountNumber} </Box>
          <Box> Enterprise Name: {newIntegrationResponse?.enterpriseName} </Box>
          <Box> User Id: {newIntegrationResponse?.userID} </Box>
          <Box> User Name: {newIntegrationResponse?.userName} </Box>
          <Box> Api Key: {newIntegrationResponse?.basicAuthCredential} </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <Grid container spacing={2} direction='column'>
      <Grid container item>
        <Grid item xs={0}></Grid>
        <Grid item xs={10}>
          <h1>Add Integration</h1>
          <Box display="flex" flexDirection="column">
          <CommonError text={pageError} /><br/>
          <Box>{getForm()}</Box>
          {newIntegrationResponse && getResult()}
          {createIntegrationRequest.loading && <CircularProgress/> }
          </Box>
        </Grid>

        <Grid item xs={2}></Grid>
      </Grid>
    </Grid>
  );
}

export default AddIntegrationPage;
