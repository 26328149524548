import dayjs from 'dayjs';

export const formatDate = (originalDate: string| undefined | null): string => {
  
  if(!originalDate) return '';

  const dateTime = dayjs(originalDate);
  if (dateTime.isValid()) {
    return dateTime.format('MM/DD/YY HH:mm:ss');
  } else {
    return '';
  }
};

export const convertDateToString = (date: Date | undefined | null): string => {

  if(!date) return '';

  const dayjsDate = dayjs(date);

  let color='green';

  const dateString = dayjsDate.format('MM/DD/YY HH:mm:ss');


  if(dayjs().diff(dayjsDate, 'day') > 2){
    color = 'red';
  }

  const finalString = `${dateString}`;

  return finalString;
};

