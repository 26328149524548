import React, { useState } from 'react';
import { Box, CircularProgress} from '@mui/material';
import { StandardButton } from '../../common/standardButton';
import LoopIcon from '@mui/icons-material/Loop';

export interface ICToggleButtonsProps {
  onChange: (canProcess: boolean) => void;
  onRefresh:()=>void;
}

export function ICToggleButtons(props: ICToggleButtonsProps){
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const turnAllOn = () => {
    setIsLoading(true);
    props.onChange(true);
  };

  const turnAllOff = () => {
    setIsLoading(true);
    props.onChange(false);
  };

  const refresh = () => {
    setIsLoading(true);
    props.onRefresh();
  };

  return (
    <Box sx={{ height: 50}}>
      {!isLoading && <><StandardButton onClick={refresh} ><LoopIcon/></StandardButton>
      <StandardButton onClick={turnAllOn} >TURN ALL (Active) ON</StandardButton>
      <StandardButton onClick={turnAllOff} >TURN ALL OFF</StandardButton></>}
      {isLoading && <CircularProgress />}
    </Box>
  );
};
