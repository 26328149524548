import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { config } from "./config";

export interface LogContent{
    userName?:string,
    action:WebAction,
    ddtags?:TagItem[]
}

export interface WebAction{
    name:string,
    params:any
}

export interface TagItem{
    name:string,
    value:string
}

interface LogItem{
    host:string,
    env:string,
    service:string,
    correlationId:string,
    userName?:string,
    category:string,
    ddSource:string,
    status:string,
    content:WebAction,
    ddtags?:string
}

export async function logInfo(content:LogContent){

    const logItem:LogItem = {
        host:'default-host',
        env:config.environment,
        service:'nova-ui',
        correlationId:uuidv4(),
        userName:content.userName,
        category:'UIAction',
        ddSource:'bgui',
        status:'info',
        content:content.action,
        //ddtags:'tagName:TBD' //todo:need to flatten logs
    };

    await logToDataDog(logItem);

}

async function logToDataDog(logItem:LogItem): Promise<void>{

    const host=`https://http-intake.logs.datadoghq.com`;
    const apiKey=`pubac3c8021d8c5a461df9001e9111b2408`;
    const reqId= uuidv4();

    const url = `${host}/v1/input?dd-api-key=${apiKey}&dd-request-id=${reqId}`;
    const response = await axios.post(url,logItem, {timeout:5000});
  };