import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, Switch, Tooltip } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';


import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import CloseIcon from '@mui/icons-material/Close';
import ArticleIcon from '@mui/icons-material/Article';
import EditIcon from '@mui/icons-material/Edit';

import { ImporterItem } from '../../models/schema';
import { formatDate } from '../../common/helpers';
import { dataDogImporterQueryBuilder } from '../../common/dataDogQueryBuilder';
import { useAuth, USER_ROLES } from '../../auth';

interface RowItem {
  name: string;
  adapterType: string;
  canProcess: boolean;
  isActive: boolean;
  lastFileProcessed: string;
  lastRun: string;
  sendResponse: boolean;
  bookRequestPath: string;
  bookResponsePath: string;
  host: string;
  apiUser: string;
  apiPassword: string;
  userName: string;
  port: number | undefined;
  password: string;
  errorEmail: string;
  importerName: string;
}

export interface ICTableProps {
  importers: ImporterItem[];
  onCanProcess: (importerName: string, canProcess: boolean) => void;
  onEdit:(importerName: string) => void;
}

export function ICTable(props: ICTableProps){
  const [importerRows, setImporterRows] = useState<RowItem[]>([]);

  const auth = useAuth();

  useEffect(() => {
    const mapRows = props.importers.map((row) => ({
      name: row.name,
      adapterType: row.adapterType,
      canProcess: row.canProcess,
      isActive: row.isActive,
      lastFileProcessed: row.lastFileProcessed ? formatDate(row.lastFileProcessed) : '',
      lastRun: row.lastRun ? formatDate(row.lastRun) : '',
      sendResponse: row.sendResponse,
      bookRequestPath: row.bookRequestPath,
      bookResponsePath: row.bookResponsePath,
      host: row.host,
      apiUser: row.apiUser,
      apiPassword: row.apiPassword,
      userName: row.userName,
      port: row.port ? row.port : undefined,
      password: row.password,
      errorEmail: row.errorEmail,
      importerName: row.name,
    }));

    setImporterRows(mapRows);
  }, [props]);

  const displayBool = (params: GridCellParams) => {
    const isTrue = params.value as boolean;

    return (
      <strong>
        {isTrue && <CheckCircleIcon color='success' fontSize='large' />}
        {!isTrue && <CancelIcon sx={{ color: 'red' }} fontSize='large' />}
      </strong>
    );
  };

  const renderEditButton = (params: GridCellParams) => {

    const importerName = params.id as string;
    return (
      <Tooltip title="View importer details">
         <IconButton onClick={(e) => props.onEdit(importerName)}>
              <EditIcon />
          </IconButton>
          </Tooltip>
    );
  };


  const renderLogButton = (params: GridCellParams) => {
    return (
      <Tooltip title="View logs in DataDog">
      <strong>
        <a
          href={dataDogImporterQueryBuilder(params.value as string)}
          target='_blank'
          rel='noreferrer'
        >
         <IconButton>
              <ArticleIcon />
            </IconButton>
        </a>
      </strong>
      </Tooltip>
    );
  };


  const displaySwitch = (params: GridCellParams) => {
    const isTrue = params.value as boolean;
    const importerName = params.id as string;

    const thisImporter = importerRows.find(x=>x.importerName === importerName);
    let isActive = thisImporter?.isActive;

    return (
      <strong>
        <Switch
          checked={isTrue}
          disabled={!isActive}
          color="success"
          onChange={(e, checked) => props.onCanProcess(importerName, checked)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </strong>
    );
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'adapterType', headerName: 'Type', width: 100 },
    {
      field: 'isActive',
      headerName: 'Is Active',
      width: 110,
      renderCell: displayBool,
    },
    {
      field: 'canProcess',
      headerName: 'Can Process',
      width: 125,
      renderCell: auth.userHasOperation(USER_ROLES.NOVA_ADMIN) ? displaySwitch : displayBool,
    },
    { field: 'lastRun', headerName: 'Last Run (UTC)', width: 190 },
    { field: 'lastFileProcessed', headerName: 'Last File (UTC)', width: 190 },
    {
      field: 'sendResponse',
      headerName: 'Send Response',
      width: 125,
      renderCell: displayBool,
    },
    {
      field: 'importerName',
      headerName: '',
      width: 75,
      renderCell: renderLogButton,
      disableColumnMenu:true,
      disableReorder:true,
      filterable:false,
      sortable:false
    },
  ];

  const editGridColDef = {
    field: 'importerEdit',
      headerName: ' ',
      width: 75,
      renderCell: renderEditButton,
      disableColumnMenu:true,
      disableReorder:true,
      filterable:false,
      sortable:false
  };

  if(auth.user?.isSupport){
    columns.push(editGridColDef);
  }


  return (
    <Box sx={{ height: 800, width: 1200 }}>
      <DataGrid
        autoHeight
        rows={importerRows}
        density='compact'
        disableSelectionOnClick
        
        pageSize={50}
        columns={columns}
        style={{backgroundColor:'#FFFFFF'}}
        getRowId={(row) => row.name}
      />
    </Box>
  );
};
