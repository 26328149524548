import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

export interface CommonCheckboxProps{
    id:string,
    label?:string,
    readonly?:boolean,
    checked?:boolean,
    onChange?: (isChecked:boolean) => void
}

export function CommonCheckBox(props:CommonCheckboxProps){

    function handleOnChange(
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
      ){
        if(props.onChange){
            props.onChange(checked);
        }
      };

    return (
        <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id={props.id}
                  checked={props.checked}
                  onChange={handleOnChange}
                  
                />
              }
              label={props.label}
            />
          </FormGroup>
    );
}