import React from 'react';
import { Grid } from '@mui/material';

export function NotFoundPage() {
  return (
    <Grid container spacing={2} direction='column'>
      <Grid container item>
        <Grid item xs={1}></Grid>
        <Grid item xs={8}>
          <h1>NotFound Page</h1>
        </Grid>

        <Grid item xs={2}></Grid>
      </Grid>
    </Grid>
  );
}
