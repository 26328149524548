import { useState } from "react";
import { useAuth, USER_ROLES } from '../auth';
import { getItem, setItem } from "../utils/indexDbCacheManager";


export function useEnterpriseCacheApi(apiFunc: any) {

    const [data, setData] = useState<any | undefined>(undefined);
    const [status, setStatus] = useState<number | undefined>(undefined);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const auth = useAuth();

    const request = async (...args: any[]) => {
        setLoading(true);
        setStatus(undefined);
        setError('');

        console.log('get enterprise list cached data');
        const cachedData = await getItem('enterpriseList');

        if (cachedData) {
            setData(cachedData);
            setStatus(200);
            setLoading(false);
            return;
        }

        try {
            const idsUser = await auth.actions.getUser();
            console.log('get user in hook', idsUser);

            let accessToken = idsUser?.access_token;
            const result = await apiFunc(accessToken, ...args);

            if (result) {
                setStatus(200);
                setData(result);
                await setItem('enterpriseList', result, 600);
            }

        } catch (error: any) {
            console.log(`There was an axios error`);
            console.log(error.message);
            console.log(error.response?.data);

            const statusCode = error.response?.status;
            console.log(`status code `, statusCode);

            if(statusCode && statusCode === 404){
                setError(`No data found`);
            }
            else if (error.response?.data) {
                setError(error.response.data);
            }
            else {
                setError(error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return {
        status,
        data,
        error,
        loading,
        request
    };
};
