
export interface SchemaList{
    name?: string;
    schema: SchemaListItem[];
}

export interface SchemaListItem{
    name: string;
    dataType: string;
}

export function typescriptInterfaceToSchemaList(input:string): SchemaList {
    const schemaList: SchemaList = {
        name: '',
        schema: []
    };

    const lines = input.split('\n');
    const className = lines[0].replace('export interface ', '').replace('{', '').trim();
    schemaList.name = uppercaseFirstLetter(className);

    for (let i = 1; i < lines.length - 1; i++) {
        const line = lines[i].trim();
        const parts = line.split(':');
        if(parts.length > 1){
            const dataType = mapTSToGeneralType(parts[1].replace(';', '').trim());
            const name = lowercaseFirstLetter(parts[0].replace('?','').trim());
            schemaList.schema.push({name: name, dataType: dataType});
        }
    }

    return schemaList;
};


export function cSharpClassToSchemaList(input:string): SchemaList {
    const schemaList: SchemaList = {
        name: '',
        schema: []
    };

    const lines = input.split('\n');
    const className = lines[0].replace('public class ', '').replace('{', '').trim();
    schemaList.name = uppercaseFirstLetter(className);

    for (let i = 1; i < lines.length - 1; i++) {
        const line = lines[i].trim();
        const parts = line.split(' ');
        if(parts.length > 2){
            const dataType = parts[1];
            const name = lowercaseFirstLetter(parts[2].trim());
            schemaList.schema.push({name: name, dataType: dataType});
        }
    }

    return schemaList;
};

export function  jsonToSchemaList(name:string, input:string): SchemaList {
   
    const schemaList: SchemaList = {
        name: uppercaseFirstLetter(name),
        schema: []
    };
    
    const jsonObject = JSON.parse(input);

      for (const key in jsonObject) {
        if (Object.hasOwnProperty.call(jsonObject, key)) {
          const element = jsonObject[key];

          let type = 'string';

            if(typeof element === 'number'){
                if(Number.isInteger(element)){
                    type = 'int';
                }
                else{
                    type = 'double';
                }
            }
            else if(typeof element === 'boolean'){
                type = 'bool';
            }
            else if (typeof element === 'string') {
                // Attempt to parse the string as a date
                const date = Date.parse(element);
                if (!isNaN(date)) {
                    // The string is a valid date
                    type = 'DateTime';
                }  else {
                    // Check if the string is a GUID
                    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
                    if (guidRegex.test(element)) {
                        // The string is a valid GUID
                        type = 'Guid';
                    } else {
                        // The string is not a date or a GUID
                        type = 'string';
                    }
                }
            }
            else{
                type = 'string';
            }
          
            schemaList.schema.push({name: lowercaseFirstLetter(key), dataType: type});
        }
      }

      return schemaList;
  };


  export function  generateCSharpClassFromSchema(schemaList:SchemaList): string{
   
      let retString = `public class ${schemaList.name ?? 'Example'}\n{\n`;
      
      for (const item of schemaList.schema) {
        retString += `    public ${item.dataType} ${uppercaseFirstLetter(item.name)} { get; set; }\n`;
        }
      
        retString += "}";
  
      return retString;
  };

  export function  generateCSharpMapperClassFromSchema(schemaList:SchemaList): string{

    const dataTypePrefix = schemaList.name ?? 'Sample';
   
    let retString = `public static ${dataTypePrefix}Dto MapToDto(${dataTypePrefix}Item source)\n{\n`;
    retString += `   var destination = new ${dataTypePrefix}Dto \n   {\n`;

    for (const item of schemaList.schema) {
        retString += `      ${uppercaseFirstLetter(item.name)} = source.${uppercaseFirstLetter(item.name)},\n`;
      }

      retString += "    };\n\n";
      retString += "    return destination;\n";
    
      retString += "}";

    return retString;
};

  export function  generateTypescriptInterfaceFromSchema(schemaList:SchemaList): string{
   
    let retString = `export interface ${schemaList.name ?? 'RootObject'}\n{\n`;
    
    for (const item of schemaList.schema) {
        retString += `    ${item.name}:${mapGeneralTypeToTS(item.dataType)};\n`;
      }
    
      retString += "}";

    return retString;
};

export function  generateTypeScriptMapperClassFromSchema(schemaList:SchemaList): string{

    const dataTypePrefix = schemaList.name ?? 'Sample';
   
    let retString = `export function  MapToDto(source : ${dataTypePrefix}Item): ${dataTypePrefix}Dto {\n`;
    retString += `  const destination: ${dataTypePrefix}Dto =  {\n`;

    for (const item of schemaList.schema) {
        retString += `      ${item.name} = source.${item.name},\n`;
      }

      retString += "    }\n\n";
      retString += "    return destination;\n";
    
      retString += "}";

    return retString;
};

export function generateJsonFromSchema(schemaList:SchemaList): string{
    let retString = "{\n";

    for (const item of schemaList.schema) {
        retString += `    "${item.name}": ${mapGeneralTypeToJSON(item.dataType)},\n`;
      }

      retString += "}";

    return retString;
}


function mapGeneralTypeToTS(type:string): string{
   switch (type) {
        case 'int':
            return 'number';
        case 'double':
            return 'number';
        case 'string':
            return 'string';
        case 'bool':
            return 'boolean';
        case 'DateTime':
            return 'Date';
        case 'Guid':
            return 'string';
        case 'string':
            return 'string';
        default:
            return 'string';
    }
}

function mapTSToGeneralType(type:string): string{
    switch (type) {
         case 'number':
             return 'int';
         case 'boolean':
             return 'bool';
         case 'string':
            return 'string';
         default:
             return 'string';
     }
 }

function mapGeneralTypeToJSON(type:string): string{
   
    switch (type) {
        case 'int':
            return '0';
        case 'bool':
            return 'false';
        default:
            return '"string"';
    }
}

function lowercaseFirstLetter(str: string): string {
    if (!str) return str;
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  function uppercaseFirstLetter(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }


