import { Box } from "@mui/material";
import { ShipmentDetailGrid } from "./shipmentDetailGrid";
import { useState } from "react";
import { StandardButton } from "../../common/standardButton";

export interface ShipmentDetailTabProps{
    shipmentDetails?:any[];
}


export function ShipmentDetailTab(props:ShipmentDetailTabProps){
  const [showAllTables, setShowAllTables] = useState<boolean>(false);

  const openAll = () => {
    setShowAllTables(true);
  };

  const closeAll = () => {
    setShowAllTables(false);
  };
   
  function getTables(){

    return (<Box>
      {props.shipmentDetails && props.shipmentDetails.map((item) => {
      
                return (
                  <Box key={item[0].Table}>
                    <ShipmentDetailGrid key={item[0].Table} tableData={item} showTable={showAllTables} />
                    <br/>
                  </Box>
                );
              })}
          </Box>);
  }

    return(<>
    <Box>
      <StandardButton onClick={openAll} >Open All</StandardButton>
      <StandardButton onClick={closeAll} >Close All</StandardButton></Box>
    {props.shipmentDetails && props.shipmentDetails.length === 0 && <p>No data was found for this reference. </p>}
    {getTables()}
    </>
    );
}