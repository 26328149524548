export const PATHS = {
    HOME: '/',
    SIGN_IN_CALLBACK: '/auth-callback',
    SHIPMENT_QUERY: '/shipment-details',
    SHIPMENT_DETAILS: '/shipment-details/:refType/:refValue',
    LOG_QUERY: '/logs',
    IMPORT_CONFIG: '/integration/importConfig',
    ADD_INTEGRATION: '/integration/create',
    TOOLS_BASE64: '/tools/base64',
    TOOLS_PASS_GENERATOR:'/tools/password',
    TOOLS_CODE_GENERATOR:'/tools/generate-code',
    TOOLS_FORMAT_LOG:'/tools/formatLog',
    CONFIG_ENTERPRISE:'config/enterprise',
    ENTERPRISE_LOOKUP:'/enterprise-lookup',
    CONFIG_GROUP:'/group-lookup',
} as const;
