import {Autocomplete, Box, Card, Chip, CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from '@mui/material';
import {DataGrid, GridCellParams, GridColDef} from '@mui/x-data-grid';
import {useEffect, useMemo, useState} from 'react';
import {getEnterpriseConfigByEIDApi, getEnterpriseConfigsApi, getEnterpriseCredentialsApi, getEnterpriseGroupsApi, getEnterpriseInvoicePushConfigByEIDApi, getEnterprisePushConfigByEIDApi, getImporterListApi, getLegalEnterprisesApi} from '../../../api/novaApi';
import {CommonError} from '../../../common/commonError';
import {useApi} from '../../../hooks/useApi';
import {EnterpriseConfigItem, EnterpriseCredentialItem, EnterpriseInvoicePushConfigCommandItem, EnterpriseInvoicePushConfigItem, EnterpriseItem, EnterprisePushConfigItem, GroupItem, ImporterItem} from '../../../models/schema';
import { StandardCheckBox } from '../../../common/standardCheckBox';
import { CommonCheckBox } from '../../../common/commonCheckBox';
import { CommonTextBox } from '../../../common/commonTextBox';
import { CommonDropDown } from '../../../common/commonDropDown';
import LoopIcon from '@mui/icons-material/Loop';
import EditIcon from '@mui/icons-material/Edit';
import MapIcon from '@mui/icons-material/Map';
import { getItem, setItem } from '../../../utils/cacheManager';
import JSONPretty from 'react-json-pretty';
import { jsonFormatTheme } from '../../../jsonFormatTheme';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { convertDateToString, formatDate } from '../../../common/helpers';
import EditableJsonTextBox from '../../../common/editableJsonTextBox';
import { StandardButton } from '../../../common/standardButton';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark, materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { EditPushInvoiceConfigForm } from '../pushInvoiceConfigs/editPushInvoiceConfigForm';
import { PushInvoiceConfigDetail } from '../pushInvoiceConfigs/pushInvoiceConfigDetail';


export function EnterpriseConfigPage() {

  const [pageError, setPageError] = useState<string>('');

  const [selectedEnterprise, setSelectedEnterprise] = useState<EnterpriseItem | null>(null);
  const [enterpriseList, setEnterpriseList] = useState<EnterpriseItem[]>([]);

  const [invoicePushConfigList, setInvoicePushConfigList] = useState<EnterpriseInvoicePushConfigItem[]>([]);
  const [pushConfigList, setPushConfigList] = useState<EnterprisePushConfigItem[]>([]);
  const [configList, setConfigList] = useState<EnterpriseConfigItem[]>([]);
  const [importerList, setImporterList] = useState<ImporterItem[]>([]);
  const [credentialList, setCredentialList] = useState<EnterpriseCredentialItem[]>([]);

  const [selectedMap, setSelectedMap] = useState<string | null>(null);
  const [editInvoiceMap, setEditInvoiceMap] = useState<boolean>(false);

  const [showInvoicePushConfigForm, setShowInvoicePushConfigForm] = useState<boolean>(false);


  const getEnterpriseRequest = useApi(getLegalEnterprisesApi);
  const getImporterRequest = useApi(getImporterListApi);
  const getPushRegistryRequest = useApi(getEnterprisePushConfigByEIDApi);
  const getInvoicePushRegistryRequest = useApi(getEnterpriseInvoicePushConfigByEIDApi);
  const getConfigRequest = useApi(getEnterpriseConfigByEIDApi);
  const getCredentialsRequest = useApi(getEnterpriseCredentialsApi);

  

 let customerPrimaryRef = '';

  if(configList && configList.length > 0){
    customerPrimaryRef = configList[0].customerPrimaryReference ?? '';
  }

 let filteredImporterList =[];
  let filteredCredentialList = [];

  if(selectedEnterprise?.groupName && selectedEnterprise.groupName.length > 0){
    filteredImporterList = importerList.filter(x=>x.groupName ===selectedEnterprise?.groupName);
    filteredCredentialList = credentialList.filter(x=>x.groupName ===selectedEnterprise?.groupName);
  }


  //initial load
  useEffect(() => {
   getEnterpriseList();
  }, []);

  //get enterprise list to populate the dropdown
  useEffect(() => {
    if(getEnterpriseRequest.data){
      setEnterpriseList(getEnterpriseRequest.data);
      setItem("enterpriseList",getEnterpriseRequest.data,600);
    }
  }, [getEnterpriseRequest.data]);


  useEffect(() => {
    if(getInvoicePushRegistryRequest.data){
      setInvoicePushConfigList(getInvoicePushRegistryRequest.data);
    }
  }, [getInvoicePushRegistryRequest.data]);


  useEffect(() => {
    if(getPushRegistryRequest.data){
      setPushConfigList(getPushRegistryRequest.data);
    }
  }, [getPushRegistryRequest.data]);


  useEffect(() => {
    if(getConfigRequest.data){
      setConfigList(getConfigRequest.data);
    }
  }, [getConfigRequest.data]);


  useEffect(() => {
    if(getImporterRequest.data){
      setImporterList(getImporterRequest.data);
    }
  }, [getImporterRequest.data]);

  useEffect(() => {
    if(getCredentialsRequest.data){
      setCredentialList(getCredentialsRequest.data);
    }
  }, [getCredentialsRequest.data]);

 function toggleEditInvoice(){
   setEditInvoiceMap(!editInvoiceMap);
 }

  function clear(){
      setSelectedEnterprise(null);
      setInvoicePushConfigList([]);
      setPushConfigList([]);
      setConfigList([]);
      setImporterList([]);
      setCredentialList([]);
  }

  function getEnterpriseList(){
    const storedEnterpriseList = getItem("enterpriseList");
    if(!storedEnterpriseList){
      getEnterpriseRequest.request();
    }
    else{
      setEnterpriseList(storedEnterpriseList);
    }
  }

  const changeEnterprise = (
    e: React.SyntheticEvent<Element, Event>,
    value: EnterpriseItem | null
  ) => {
    if (value) {
      setSelectedEnterprise(value);
      getInvoicePushRegistryRequest.request(value.enterpriseId);
      getPushRegistryRequest.request(value.enterpriseId);
      getConfigRequest.request(value.enterpriseId);
      getImporterRequest.request();
      getCredentialsRequest.request();

    }
    else{
      clear();
    }
  };

  function onCloseInvoicePushHandler(){
    setShowInvoicePushConfigForm(false);

  }
  
  function onUpdateInvoicePushHandler(item:EnterpriseInvoicePushConfigCommandItem){
  
  }

  function displayMap(e: React.SyntheticEvent<Element, Event>,identifierValue: string){
    e.preventDefault();
    setPageError('');
    setSelectedMap('');
    const map = invoicePushConfigList.find(x=>x.identifierValue === identifierValue);
    if(map){
      setSelectedMap(JSON.stringify(map.mapTemplate));
    }
    else{
      setPageError('Map not found');
    }
  }

  function saveEditInvoiceMap(responseData:any){
    setSelectedMap(JSON.stringify(responseData));
    setEditInvoiceMap(false);
  }

  function displayEnterpriseSelect(){
    return (
    <Box sx={{padding:'15px'}}>
    <Autocomplete
      autoComplete
      includeInputInList
      id='enterprise'
      size="small"
      options={enterpriseList}
      getOptionLabel={(option: EnterpriseItem) => `${option.name}-${option.accountNumber}`}
      onChange={changeEnterprise}

      sx={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} label='Enterprise' />
      )}
    />
  </Box>);
  }

  function displayEnterpriseData(){
    return (
      <Box>
        <h3>Info</h3>

      <Card sx={{padding:'10px'}}>
     {selectedEnterprise && <Box><span style={{color:'#2C3E50'}} >Name:</span> <span style={{fontWeight:900}} >{selectedEnterprise.name}</span> &nbsp;&nbsp;&nbsp;
     <span style={{color:'#2C3E50'}} >ID:</span> <span style={{fontWeight:900}} > {selectedEnterprise.enterpriseId}</span>  &nbsp;&nbsp;&nbsp;
     <span style={{color:'#2C3E50'}} >Account: </span> <span style={{fontWeight:900}} >{selectedEnterprise.accountNumber}</span> &nbsp;&nbsp;&nbsp;
     <span style={{color:'#2C3E50'}} >Group: </span> <span style={{fontWeight:900}} >{selectedEnterprise.groupName}</span></Box>}
     </Card>
   
    <h3>Api Keys</h3>

    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>ClientId</TableCell>
          <TableCell>ApiKey</TableCell>
          <TableCell>Permissions</TableCell>
          <TableCell>IsActive</TableCell>
          <TableCell>Last Login</TableCell>
          <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {credentialList && credentialList.filter(x=>x.groupName ===selectedEnterprise?.groupName).map((row) => 
        <TableRow
              key={1}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{row.clientId}</TableCell>
               <TableCell>{row.clientSecret}</TableCell>
               <TableCell>All</TableCell>
               <TableCell>{displayBool(!row.isLockedOut)}</TableCell>
               <TableCell>{convertDateToString(row.lastLogin)}</TableCell>
               <TableCell><LoopIcon /></TableCell>
         </TableRow>)}

         </TableBody>
      </Table>
    </TableContainer>


    <h3>Configs</h3>
    <Card sx={{padding:'10px'}}>
    <table>
      <tbody>
      <tr> 
        <td><CommonCheckBox id='Prevent Book' label='Prevent Book' /></td> 
           <td><CommonCheckBox id='Enforce Address Book Times' label='Enforce Address Book Times' /></td>
           <td><CommonCheckBox id='Enforce Must Deliver' label='Enforce Must Deliver' /></td>
           <td><CommonCheckBox id='Show Charge Rate' label='Show Charge Rate' /></td>
           <td><CommonCheckBox id='Hide Cost Rate' label='Hide Cost Rate' /></td>
           <td><CommonCheckBox id='Hide Price Rate' label='Hide Price Rate' /></td>
          
        </tr>
        <tr> 
           <td><CommonCheckBox id='Email New Shipment' label='Email New Shipment' /></td>
           <td><CommonCheckBox id='Notify Book LTL' label='Notify Book LTL' /></td>
           <td><CommonCheckBox id='Notify Book TL' label='Notify Book TL' /></td>
           <td><CommonCheckBox id='Notify Cancel' label='Notify Cancel' /></td>
           <td><CommonCheckBox id='Notify Pending' label='Notify Pending' /></td> 
           <td><CommonCheckBox id='Notify Update' label='Notify Update' /></td>
        </tr>
         <tr><td><CommonCheckBox id='Enforce Customer Pri Ref' label='Enforce Customer Pri Ref' /></td> 
           <td colSpan={3}><CommonTextBox id='Customer Primary Ref' label='Customer Primary Ref' value={customerPrimaryRef} onChange={()=>{}}  /></td>
          </tr>

          <tr><td><CommonCheckBox id='Enforce Must Deliver' label='Enforce Must Deliver' /></td>
           <td colSpan={3}><CommonDropDown id='Cannot Meet Transit' label='Cannot Meet Transit' items={[{id:'Save',name:'Save'}]} width={300} value={''} onChange={()=>{}}  /></td>
          </tr>

          <tr>
           <td colSpan={3}><CommonDropDown id='No Rate Action' label='No Rate Action' items={[{id:'Save',name:'Save'}]} width={300} value={''} onChange={()=>{}}  /></td>
          </tr>
          </tbody>
    </table>
    </Card>

    <h3>Importer</h3>

    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Can Process</TableCell>
          <TableCell>Last File</TableCell>
          <TableCell>View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

{importerList && importerList.filter(x=>x.groupName ===selectedEnterprise?.groupName).map((row) => 
        <TableRow
              key={1}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
               <TableCell>{row.name}</TableCell>
               <TableCell>{row.adapterType}</TableCell>
               <TableCell>{displayBool(row.canProcess)}</TableCell>
               <TableCell>{formatDate(row.lastFileProcessed)}</TableCell>
               <TableCell><EditIcon /></TableCell>
         </TableRow>)}


         </TableBody>
      </Table>
    </TableContainer>


    <h3>Push Registry</h3>

    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>ID Type</TableCell>
          <TableCell>ID Value</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Push Adapter</TableCell>
          <TableCell>Ship</TableCell>
          <TableCell>Doc</TableCell>
          <TableCell>Track</TableCell>
          <TableCell>T Accept</TableCell>
          <TableCell>View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

        {pushConfigList && pushConfigList.map((row) => 

        <TableRow
              key={1}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
               <TableCell>{row.identifierType}</TableCell>
               <TableCell>{row.identifierValue}</TableCell>
               <TableCell>{row.name}</TableCell>
               <TableCell>{row.pushAdapter}</TableCell>
               <TableCell>{displayBool(row.isPushShipment ?? false)}</TableCell>
               <TableCell>{displayBool(row.isPushDocument ?? false)}</TableCell>
               <TableCell>{displayBool(row.isPushTracking ?? false)}</TableCell>
               <TableCell>{displayBool((row.isPush990Auto || row.isPush990CarrierSelect || row.isPush990Standard) ?? false)}</TableCell>
               <TableCell><EditIcon /></TableCell>
         </TableRow>)}

         </TableBody>
      </Table>
    </TableContainer>

    <h3>Push Invoice Registry</h3>
    {selectedEnterprise && <PushInvoiceConfigDetail identifierType='ENTERPRISE' identifierValue={selectedEnterprise.enterpriseId.toString()} />}


<br/><br/><br/>

    </Box>

    );
  }

  const displayBool = (isTrue:boolean) => {

    return (
      <strong>
        {isTrue && <CheckCircleIcon color='success' fontSize='large' />}
        {!isTrue && <CancelIcon sx={{ color: 'red' }} fontSize='large' />}
      </strong>
    );
  };



  return (
    <Grid container spacing={2} direction='column'>
      <Grid container item>
        <Grid item xs={0}></Grid>
        <Grid item xs={10}>
          <Box sx={{backgroundColor:'yellow',marginTop:'15px', fontSize:'18px'}}><strong>DEMO Page</strong> </Box>
        <h1>Enterprise Data</h1>
        {displayEnterpriseSelect()}
        {selectedEnterprise && displayEnterpriseData()}
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Grid>
  );
}
