import { Button } from "@mui/material";


export interface CommonButtonProps{
    text:string,
    type?:'button' | 'reset' | 'submit',
    disabled?:boolean,
    onClick?:() =>void
}

export function CommonButton(props:CommonButtonProps){
    return (
        <Button variant='contained' 
        style={{marginRight: 5 }}
        size='small' 
        type={props.type}
        disabled={props.disabled}
        onClick={props.onClick}>
        {props.text}
        </Button>
    );
}